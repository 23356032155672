import { clearNotifications, notificationAtom } from '@/store/recoil/notification';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NotificationCard } from './NotificationCard';

export function NotificationPropertyPanel() {
  const notifications = useRecoilValue(notificationAtom);
  const clearNotificationsFn = useSetRecoilState(clearNotifications);
  const { t } = useTranslation(['interface', 'common']);

  const sortedNotifications = useMemo(
    () =>
      [...notifications.history, ...notifications.queue].sort(
        (a, b) => b.time.getTime() - a.time.getTime(),
      ),
    [notifications.history, notifications.queue],
  );

  return (
    <Stack
      spacing={1}
      p={2}
      height='100%'
      data-testid='notification-property-panel'
      sx={{ '&:hover': { cursor: 'default' }}}
    >
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16px',
            color: 'neutral.darker',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Notifications
        </Typography>
        <Button
          variant='text'
          onClick={() => clearNotificationsFn(null)}
        >
          {t('common:clear', 'Clear')}
        </Button>
      </Stack>
      <Divider />
      <Stack overflow='auto' gap={1}>
        {sortedNotifications.length === 0 && (
          <Typography>{t('interface:notifications.none', 'No notifications')}</Typography>
        )}
        {sortedNotifications.map((notification) => (
          <NotificationCard key={notification.id} notification={notification} />
        ))}
      </Stack>
    </Stack>
  );
}
