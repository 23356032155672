import { download } from '@modules/common/helpers/browser';
import { format } from 'date-fns';
import { useCallback } from 'react';

import { useArtefacts } from '@/modules/artefacts';
import { FloorPlan } from '@/modules/common/types/floorPlan';
import {
  isReferencePresentSelector,
  referenceDimensions,
  referenceOriginalSize,
} from '@/modules/referenceImage/store';
import { useFloorPlanGroupApi } from '@modules/api/hooks';
import { useRecoilCallback } from 'recoil';
import { groupNameSelector, useFloorPlanService } from '..';
import { useAutoSave } from './useAutoSave';
import { useBackwardCompatibility } from './useBackwardCompatibility';
import { useFloorPlanState } from './useFloorPlanState';
import { useVehicleDependencyManager } from '@/modules/vehicles';
import { useFloorplanObjectValidation } from '@/modules/floorplan/validation/useFloorplanTypeGuard';

export const useFloorPlanFile = () => {
  const { getFloorPlan, loadFloorPlanState } = useFloorPlanState();
  const { fetchVersion } = useFloorPlanService();
  const { fetch } = useFloorPlanGroupApi();
  const { save } = useAutoSave();
  const { convert } = useBackwardCompatibility();
  const { initialize: initializeArtefacts } = useArtefacts();
  const { isValidFloorplan } = useFloorplanObjectValidation();
  const { ensureVehicleDetailsAvailability, ensureVehicleAssetAvailability } =
    useVehicleDependencyManager();

  const downloadFile = useCallback((floorPlan: FloorPlan, groupName: string) => {
    floorPlan.additionalData.urn = undefined;
    floorPlan.additionalData.thumbnail = undefined;

    const version = floorPlan.hasUnsavedChanges ? floorPlan.version : floorPlan.version - 1;
    const fileContents = JSON.stringify(floorPlan);
    const fileName = `Floorplan_${groupName}-Version_${version}-${format(
      new Date(),
      'yyyyMMdd',
    )}.json`;
    const file = new File([fileContents], fileName, {
      type: 'application/json',
    });
    download(file, fileName);
  }, []);

  /**
   *
   * Returns a floor plan json file
   */
  const getFile = useCallback((floorPlan: FloorPlan, groupName: string) => {
    floorPlan.additionalData.urn = undefined;
    floorPlan.additionalData.thumbnail = undefined;

    const version = floorPlan.hasUnsavedChanges ? floorPlan.version : floorPlan.version - 1;
    const fileContents = JSON.stringify(floorPlan);
    const fileName = `Floorplan_${groupName}-Version_${version}-${format(
      new Date(),
      'yyyyMMdd',
    )}.json`;
    return new File([fileContents], fileName, {
      type: 'application/json',
    });
  }, []);

  /**
   * Downloads a floor plan json file that can be loaded later
   */
  const downloadFloorPlan = useCallback(
    async (projectId: string, groupId: string, floorPlanId: string) => {
      const floorPlan = await fetchVersion(projectId, groupId, floorPlanId);
      const group = await fetch(projectId, groupId);

      downloadFile(floorPlan, group.name);
    },
    [downloadFile, fetch, fetchVersion],
  );

  /**
   * Get the current floor plan json
   */
  const getCurrentFloorplanFile = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const floorPlan = await getFloorPlan();
        const referencePresent = await snapshot.getPromise(isReferencePresentSelector);
        if (referencePresent) {
          const originalSize = await snapshot.getPromise(referenceOriginalSize);
          const currentSize = await snapshot.getPromise(referenceDimensions);
          floorPlan.additionalData.scaleFactor = currentSize.width / originalSize.width;
        }
        return getFile(floorPlan, await snapshot.getPromise(groupNameSelector));
      },
    [getFloorPlan, getFile],
  );

  /**
   * Downloads the current floor plan json file that can be loaded later
   */
  const downloadCurrentFloorPlan = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const floorPlan = await getFloorPlan();
        const referencePresent = await snapshot.getPromise(isReferencePresentSelector);
        if (referencePresent) {
          const originalSize = await snapshot.getPromise(referenceOriginalSize);
          const currentSize = await snapshot.getPromise(referenceDimensions);
          floorPlan.additionalData.scaleFactor = currentSize.width / originalSize.width;
        }

        downloadFile(floorPlan, await snapshot.getPromise(groupNameSelector));
      },
    [getFloorPlan, downloadFile],
  );

  /**
   * Loads a floor plan from a json file
   */
  const loadFile = useCallback(
    async (file: File) => {
      const parseFile = new Promise<any>((resolve): any => {
        const fileReader = new FileReader();
        fileReader.readAsText(file, 'UTF-8');
        fileReader.onload = async (e) => {
          const fp = JSON.parse(e.target.result as string);
          if (fp.additionalData) {
            fp.additionalData.urn = undefined;
          }
          resolve(fp);
        };
      });

      const floorplan = await Promise.resolve(parseFile);

      if (isValidFloorplan(floorplan)) {
        const { vehicleTypes, vehicleAssets } = floorplan.additionalData;
        const assets = await ensureVehicleAssetAvailability(vehicleTypes, vehicleAssets);

        await ensureVehicleDetailsAvailability(vehicleTypes, assets);

        await loadFloorPlanState(convert(floorplan.additionalData));
        await initializeArtefacts();
        await save();
      }
    },
    [
      convert,
      initializeArtefacts,
      loadFloorPlanState,
      save,
      isValidFloorplan,
      ensureVehicleDetailsAvailability,
      ensureVehicleAssetAvailability,
    ],
  );

  return {
    downloadFloorPlan,
    downloadCurrentFloorPlan,
    getCurrentFloorplanFile,
    loadFile,
  };
};
