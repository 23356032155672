import { NOTIFICATION_TYPES, showNotification } from '@recoil/notification';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

export const useVisualizationNotification = () => {
  const { t } = useTranslation(['interface', 'common']);
  const showNotificationFn = useSetRecoilState(showNotification);

  const showVikingOpenFileSuccess = useCallback(() => {
    showNotificationFn({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: t('visualization.notifications.viking_open_file_success'),
    });
  }, [showNotificationFn, t]);

  const showVikingOpenFileNotSupported = useCallback(
      (simulationId: string) => {
        showNotificationFn({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('visualization.notifications.viking_open_file_not_supported', { simulationId }),
        });
      },
      [showNotificationFn, t],
  );

  const showVikingOpenFileFailed = useCallback(
    (simulationId: string) => {
      showNotificationFn({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('visualization.notifications.viking_open_file_failed', { simulationId }),
      });
    },
    [showNotificationFn, t],
  );

  const showVikingLowEventsWarning = useCallback(
    (numberOfEvents: number) => {
      showNotificationFn({
        type: NOTIFICATION_TYPES.WARNING,
        message: t('visualization.notifications.viking_open_file_warning_low_event', {
          numberOfEvents,
        }),
      });
    },
    [showNotificationFn, t],
  );

  return { showVikingOpenFileSuccess, showVikingOpenFileNotSupported, showVikingOpenFileFailed, showVikingLowEventsWarning };
};
