type DTExceptionOptions = {
    code?: string[]
    cause?: any
}

export class DTException extends Error {
    public codeStr: string;
    public code: string[];
    public cause: any;

    constructor(message: string, private options: DTExceptionOptions) {
        super(message);
        this.name = "DTException";
        this.codeStr = options.code && `(${options.code.join(", ")})`
        this.code = options.code
        this.cause = options.cause
    }

    log(groupName: string) {
        console.group(groupName)
        console.error(this.message)
        console.log('Code', this.code)
        console.log('Cause')
        console.dir(this.cause)
        console.groupEnd()
    }
}
