import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { useNavigation } from '@/modules/common/hooks';
import { useFloorPlanGroupApi, useProjectApi } from '@modules/api/hooks';
import { useLoadProject } from '@/modules/floorplan';

import { floorPlanGroupSelector, floorPlanGroupIdsSelector } from '../store/floorPlanGroup';
import { useNotificationsContext, NotificationsContext } from '../../notifications';
import { useTrackTiming } from '@/modules/insight/hooks/useTrackTiming';

export const useFloorplanAdmin = () => {
  const { projectId } = useParams();
  const { fetchAll: fetchAllGroups } = useFloorPlanGroupApi();
  const { fetch: fetchProject } = useProjectApi();
  const { load: loadProject } = useLoadProject();
  const { goToPortal } = useNavigation();
  useNotificationsContext(NotificationsContext.FLOORPLAN_ADMIN);
  const [projectIsLoading, setProjectIsLoading] = useState(false);
  const [floorPlanGroupsAreLoading, setFloorPlanGroupsAreLoading] = useState(false);
  const trackTiming = useTrackTiming();

  const getProject = useCallback(
    async (projectId: string) => {
      try {
        setProjectIsLoading(true);
        const project = await fetchProject(projectId);

        loadProject(project);
      } catch (e) {
        console.error(e);
        goToPortal(true);
      }

      setProjectIsLoading(false);
    },
    [fetchProject, setProjectIsLoading, loadProject, goToPortal],
  );

  const getFloorplanGroups = useRecoilCallback(
    ({ set }) =>
      async (projectId: string) => {
        const startTime = performance.now();
        try {
          setFloorPlanGroupsAreLoading(true);
          const floorPlanGroups = await fetchAllGroups(projectId);

          floorPlanGroups.forEach((item) => set(floorPlanGroupSelector(item.id), item));
          set(
            floorPlanGroupIdsSelector,
            floorPlanGroups.map((item) => item.id),
          );
        } catch (e) {
          console.error(e);
        } finally {
          trackTiming('LOAD_FLOORPLAN_GROUPS', startTime);
        }

        setFloorPlanGroupsAreLoading(false);
      },
    [fetchAllGroups, fetchProject, setProjectIsLoading, loadProject, goToPortal, trackTiming],
  );

  useEffect(() => {
    getProject(projectId);
    getFloorplanGroups(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return {
    projectIsLoading,
    floorPlanGroupsAreLoading,
  };
};
