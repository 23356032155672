import { atom } from 'recoil';

export const NOTIFICATION_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
} as const;

type NotificationTypes = typeof NOTIFICATION_TYPES[keyof typeof NOTIFICATION_TYPES];

export type NotificationProps = {
  type: NotificationTypes;
  message: string;
  id?: string;
  action?: React.ReactNode;
  keepInHistory?: boolean;
  timeOut?: number;
  removeDuplicates?: boolean;
  showSupportMessage?: boolean;
};

export type NotificationMessage = NotificationProps & {
  id: string;
  time: Date;
};

export type NotificationQueue = NotificationMessage[];

export type NotificationAtom = {
  queue: NotificationQueue;
  history: NotificationQueue;
  open: boolean;
};

export const notificationAtom = atom<NotificationAtom>({
  key: 'NotificationAtom',
  default: {
    queue: [],
    history: [],
    open: false,
  },
});
