import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { t } from 'i18next';
import { useSetRecoilState } from 'recoil';

export const useFileTypeValidator = () => {
  const showNotificationFn = useSetRecoilState(showNotification);

  const validateFileType = (file: File, acceptableExtensions: string[]): boolean => {
    const mimeTypeExtension = file.type.split('/').pop()?.toLowerCase();
    const lowerCaseAcceptableExtensions = acceptableExtensions.map((ext) => ext.toLowerCase());

    if (mimeTypeExtension && lowerCaseAcceptableExtensions.includes(mimeTypeExtension)) {
      return true;
    }

    const fileNameExtension = file.name.split('.').pop()?.toLowerCase();

    if (fileNameExtension && lowerCaseAcceptableExtensions.includes(fileNameExtension)) {
      return true;
    }

    const currentType = mimeTypeExtension || fileNameExtension || 'unknown';
    const expectedTypes = acceptableExtensions.join(', ');

    showNotificationFn({
      type: NOTIFICATION_TYPES.WARNING,
      message: t(
        'errors:settings.incompatible_file_extension',
        `Incompatible file. Current type: ${currentType}, expected: ${expectedTypes}`,
        { currentType, expectedTypes },
      ),
    });

    return false;
  };
  return {
    validateFileType,
  };
};
