import { useSetRecoilState } from 'recoil';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { t } from 'i18next';
import { useObjectValidation } from '@/modules/common/hooks/useObjectValidation';

const requiredKeys = [
  'id',
  'hasUnsavedChanges',
  'project',
  'groupId',
  'created',
  'lastUpdated',
  'additionalData',
  'version',
  'lastUpdatedBy',
];

export const useFloorplanObjectValidation = () => {
  const showNotificationFn = useSetRecoilState(showNotification);
  const { validateKeys } = useObjectValidation();

  const isValidFloorplan = (floorplan: object) => {
    if (!floorplan) {
      return false;
    }

    if (!validateKeys(floorplan, requiredKeys)) {
      showNotificationFn({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('errors:invalid_data_format'),
      });

      return false;
    }

    return true;
  };

  return { isValidFloorplan };
};
