import { PropertyPanel as CommissioningPropertyPanel } from '@/modules/commissioning';
import { PropertyPanel as Commissioning3TE25PropertyPanel } from '@/modules/commissioning3TE25';
import { isCommissioningSubModule } from '@/modules/common/helpers/navigation';
import { Module } from '@/modules/common/types/navigation';
import { PropertyPanel as SalesPropertyPanel } from '@/modules/salesWorkspace';
import { PropertyPanel as SetupPropertyPanel } from '@/modules/setup';
import { PropertyPanel as SimulationPropertyPanel } from '@/modules/simulation';
import { PropertyPanel as VersioningPropertyPanel } from '@/modules/versioning';
import { PropertyPanel as VisualizationPropertyPanel } from '@/modules/visualization';
import navAtom from '@/store/recoil/nav/atom';
import { useRecoilValue } from 'recoil';
import { NotificationPropertyPanel } from '../../modules/notifications/components/NotificationPropertyPanel';
import notificationPanelOpen from '../../store/recoil/notification/notificationPanelOpen';
import { TabsMain } from './TabsMain';
import LayoutProperties from './layout/LayoutProperties';

export const RIGHT_PANEL_WIDTH = 320;

const modulesWithTabs = [Module.SETUP, Module.LAYOUT, Module.SIMULATION];

export function PropertiesPanelContent() {
  const nav = useRecoilValue(navAtom);
  const showNotifications = useRecoilValue(notificationPanelOpen);

  return (
    <>
      {showNotifications ? (
        <NotificationPropertyPanel />
      ) : (
        <>
          {modulesWithTabs.includes(nav) && <TabsMain />}
          {nav === Module.SETUP && <SetupPropertyPanel />}
          {nav === Module.LAYOUT && <LayoutProperties />}
          {nav === Module.SIMULATION && <SimulationPropertyPanel />}
          {nav === Module.VERSIONING && <VersioningPropertyPanel />}
          {(nav === Module.VISUALISATION || nav === Module.VIEWER) && (
            <VisualizationPropertyPanel />
          )}
          {nav === Module.SALES_CANVAS && <SalesPropertyPanel />}
          {isCommissioningSubModule(nav) && <CommissioningPropertyPanel />}
          {nav === Module.COMMISSIONING_3TE25 && <Commissioning3TE25PropertyPanel />}
        </>
      )}
    </>
  );
}
