import { AppMode } from '@/modules/common/types/general';
import { ValidationPanel } from '@modules/floorplanService/components/ValidationPanel';
import { NotificationHistory } from '@modules/notifications';
import { Grid, Stack } from '@mui/material';
import { CenterMenu } from './CenterMenu';
import { Logo, LogoMenu } from './Logo';
import { Title } from './Title';
import { UserMenu } from './UserMenu';

export const BUILDER_BAR_HEIGHT = 48;

export function BuilderBar() {
  return (
    <Grid container spacing={2} alignItems='center' data-testid='BuilderBar'>
      <Grid item xs={4}>
        <Stack direction='row' alignItems='center'>
          <Logo />
          <LogoMenu />
        </Stack>
      </Grid>
      <Grid item xs={4} style={{ textAlign: 'center' }}>
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Title />
          <CenterMenu />
        </Stack>
      </Grid>
      <Grid item xs={4}>
        <Stack direction='row' justifyContent='flex-end' alignItems='center' spacing={1}>
          <NotificationHistory />
          <ValidationPanel />
          <UserMenu appMode={AppMode.DigitalTwin} />
        </Stack>
      </Grid>
    </Grid>
  );
}
