import { useFloorPlanService as fpsService } from '@/modules/floorplanService';
import { useCanvasStore } from '@modules/canvas';
import { NOTIFICATION_TYPES, showNotification } from '@recoil/notification';
import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import {
  useFloorPlanStore,
  useGateStore,
  useKollmorgenStore,
  useLayoutStore,
  useNavitechStore,
  useSevenSenseStore,
} from '../store';
import { useApi } from './useApi';

export const useLoadData = () => {
  const { generateFloorPlanArtefacts } = fpsService();
  const { fetchLocalizationMapFiles, fetch } = useApi();
  const showNotificationFn = useSetRecoilState(showNotification);

  const load = useCallback(async () => {
    try {
      const [layout, artifacts] = await Promise.all([fetch(), generateFloorPlanArtefacts()]);
      const files = await fetchLocalizationMapFiles(layout?.localizationMaps ?? []);

      await useKollmorgenStore.getState().initialize(files.kollmorgen);
      await useSevenSenseStore.getState().initialize(files.sevenSense);
      await useNavitechStore.getState().initialize(files.navitech);
      await useFloorPlanStore.getState().initialize(artifacts);
      useLayoutStore.getState().initialize(layout?.layoutDelta);
      useGateStore.getState().initialize(artifacts, layout?.gates ?? []);
      useCanvasStore.getState().instance.zoomToFit();
    } catch (error) {
      showNotificationFn({
        type: NOTIFICATION_TYPES.ERROR,
        message: `Failed to load commissioning floor plan`,
      });
      console.error(error);
    }
  }, [fetch, fetchLocalizationMapFiles, generateFloorPlanArtefacts, showNotificationFn]);

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
