import { useSetRecoilState } from 'recoil';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { t } from 'i18next';
import { useObjectValidation } from '@/modules/common/hooks/useObjectValidation';

const requiredKeys = [
  'Name',
  'ObstructionTimeOutInSeconds',
  'OrderDistributionStrategy',
  'ManualIntervention',
  'TrafficManagementDisabled',
  'FailOnNoRouteFound',
  'CheckpointSets',
  'DecisionCheckPoints',
  'DestinationTrafficOnlyCheckpoints',
  'ForbiddenCombos',
  'OppositeDrivingDirectionPaths',
  'ParkingAndChargingLocations',
];

export const useFmsConfigObjectValidation = () => {
  const showNotificationFn = useSetRecoilState(showNotification);
  const { validateKeys } = useObjectValidation();

  const isValidFmsConfigJson = (fmsConfig: string): boolean => {
    if (!fmsConfig) {
      return false;
    }

    try {
      const parsedJson = JSON.parse(fmsConfig);
      if (!validateKeys(parsedJson, requiredKeys)) {
        showNotificationFn({
          type: NOTIFICATION_TYPES.ERROR,
          message: t('errors:invalid_data_format'),
        });
        return false;
      }

      return true;
    } catch (error) {
      console.error(error);
      showNotificationFn({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('errors:invalid_data_format'),
      });
      return false;
    }
  };

  return { isValidFmsConfigJson };
};
