import ErrorIcon from '@assets/icons/ErrorIcon';
import { theme } from '@common/components/theme';
import { tooltipClasses } from '@mui/material';

import { Type } from './types';

export const getIcon = (type: Type) => {
  if (type === 'error') return <ErrorIcon fontSize={24} />;

  return null;
};

export const getSx = (type: Type, width?: number) => {
  const defaultSx = {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.neutral.darker,
  };

  const errorSx = {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
  };

  const typeSx = type === 'error' ? errorSx : defaultSx;

  return {
    width,

    [`& .${tooltipClasses.arrow}`]: {
      color: typeSx.backgroundColor,
      overflow: 'initial',
    },

    [`& .${tooltipClasses.tooltipPlacementLeft} .${tooltipClasses.arrow}`]: {
      height: '20px',
      width: '20px',
      top: '50% !important',
      transform: 'translate(0, -50%) !important',
      '&::before': {
        transformOrigin: 'initial',
      },
    },

    [`& .${tooltipClasses.tooltipPlacementBottom} .${tooltipClasses.arrow}`]: {
      left: '50% !important',
      transform: 'translate(-50%, 0) !important',
      height: '20px',
      width: '20px',
      '&::before': {
        transformOrigin: 'initial',
      },
    },

    [`& .${tooltipClasses.tooltipPlacementTop} .${tooltipClasses.arrow}`]: {
      left: '50% !important',
      transform: 'translate(-50%, 0) !important',
      height: '20px',
      width: '20px',
      '&::before': {
        transformOrigin: 'initial',
      },
    },

    [`& .${tooltipClasses.tooltipPlacementRight} .${tooltipClasses.arrow}`]: {
      transform: 'translate(0, -50%) !important',
      top: '50% !important',
      height: '20px',
      width: '20px',
      '&::before': {
        transformOrigin: 'initial',
      },
    },

    [`& .${tooltipClasses.tooltip}`]: {
      borderRadius: 0,
      padding: 0,
      maxWidth: width,

      ...typeSx,
    },
  };
};
