import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { notificationAtom, NotificationMessage } from './atom';

const hideNotification = selector<string>({
  key: 'notification/close',
  get: () => '',
  set: ({ get, set }, id: string) => {
    const currentState = get(notificationAtom);
    const { queue } = currentState;
    const { open } = currentState;
    let { history } = currentState;

    const itemToArchive = queue.find((n: NotificationMessage) => n.id === id);
    if (itemToArchive && itemToArchive.keepInHistory) history = [...history, itemToArchive];
    const filteredQueue = queue.filter((n) => n.id !== id);
   
    set(notificationAtom, { queue: filteredQueue, history, open });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default hideNotification;
