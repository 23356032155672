import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { notificationAtom } from './atom';

const clearNotifications = selector({
  key: 'notification/clearAll',
  get: ({ get }) => get(notificationAtom),
  set: ({ set, get }) => {
    set(notificationAtom, {
      history: [],
      queue: [],
      open: get(notificationAtom).open
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default clearNotifications;
