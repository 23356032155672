import { useCallback } from 'react';
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { useFileApi } from '@/modules/api/hooks';
import { FLOORPLAN_ASSET_FILE_CONTAINER } from '../constants';
import { allVehicleAssetsSelector } from '../store';
import {
  SyncOriginalAssetFileFailed,
  SyncOriginalAssetFileSuccess,
  MimeType,
  ValidAssetType,
  ValidFileExtension,
  VehicleAssets,
} from '../types/internal';
import { useVehicleService } from './useVehicleService';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { useTranslation } from 'react-i18next';
import { useLoading } from '@/modules/common/hooks';
import { LoadingType } from '@/modules/common/types/general';
import { useUvtObjectValidation } from '@/modules/vehicles/validation/useUvtObjectValidation';

export const useUvtAssetFileSyncing = () => {
  const { addFile } = useFileApi();
  const { showLoader, hideLoader } = useLoading();
  const { fetchAndUploadAssetToFileApi } = useVehicleService();
  const { t } = useTranslation('interface');
  const notify = useSetRecoilState(showNotification);
  const [allVehicleAssets] = useRecoilState(allVehicleAssetsSelector);
  const { isValidUvtJson } = useUvtObjectValidation();
  const uploadAssetFileToFileStorage = useCallback(
    async (file: Blob, fileName: string, extension: ValidFileExtension) => {
      const uploadToFileApiResponse = await addFile(
        fileName,
        file,
        extension,
        FLOORPLAN_ASSET_FILE_CONTAINER,
      );

      if (uploadToFileApiResponse.status !== 200) {
        console.log('Failed to upload uvt to file api');
        return { success: false };
      }

      return { success: true };
    },
    [addFile],
  );

  const syncOriginalAssetFileToFileStorage = useCallback(
    async (
      vehicleId: string,
      assetType?: ValidAssetType,
    ): Promise<SyncOriginalAssetFileSuccess | SyncOriginalAssetFileFailed> => {
      const references = await fetchAndUploadAssetToFileApi(vehicleId, assetType);

      if (references && (references.uvt || references.kmMdb)) {
        return {
          success: true,
          vehicleId,
          uvtReference: references.uvt || null,
          kmMdbFileRef: references.kmMdb || null,
        };
      }

      return {
        success: false,
        vehicleId,
      };
    },
    [fetchAndUploadAssetToFileApi],
  );

  async function uploadAndSyncFileToFloorplan(
    file: File,
    assetType: ValidAssetType,
    vehicleVariantId: string,
  ) {
    if (!file) {
      notify({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('setup.floorPlan.vehicle_types.no_file_selected'),
      });
      return;
    }
    showLoader(LoadingType.TRANSPARENT);

    const fileType = assetType === 'uvt' ? 'json' : 'mdb';
    const newFileReferenceId = `${vehicleVariantId}_${assetType}_${uuid()}`;

    try {
      const fileContent = await file.text();
      const blob = new Blob([fileContent], { type: `application/${fileType}` });

      if (assetType === 'uvt') {
        if (!isValidUvtJson(fileContent)) {
          hideLoader();
          return;
        }
      }

      const fileUploadResult = await uploadAssetFileToFileStorage(
        blob,
        newFileReferenceId,
        fileType,
      );

      if (!fileUploadResult.success) {
        notify({
          type: NOTIFICATION_TYPES.ERROR,
          message: t(`setup.floorPlan.vehicle_types.failed_${assetType}_upload`),
        });
        hideLoader();
        return;
      }

      const matchingAsset = allVehicleAssets.find(
        (vehicleAsset) => vehicleAsset.vehicleVariantId === vehicleVariantId,
      );

      if (matchingAsset) {
        const assetCopy = { ...matchingAsset };
        if (assetType === 'uvt') {
          assetCopy.uvtReference = newFileReferenceId;
          assetCopy.uvtReferenceOriginal = false;
        } else {
          assetCopy.kmMdbReference = newFileReferenceId;
          assetCopy.kmMdbReferenceOriginal = false;
        }

        storeAssetReference(assetCopy);
      } else {
        console.error('Could not match the asset for:', vehicleVariantId);
      }
      hideLoader();
    } catch (error) {
      hideLoader();
      console.error('Error occurred during file upload and sync:', error);
      notify({
        type: NOTIFICATION_TYPES.ERROR,
        message: t('setup.floorPlan.vehicle_types.upload_error'),
      });
    }
  }

  const storeAssetReference = useRecoilCallback(
    ({ set }) =>
      async (vehicleAsset: VehicleAssets): Promise<void> => {
        set(allVehicleAssetsSelector, (current) => [
          ...current.filter(
            (vehicleAssets) => vehicleAssets.vehicleVariantId !== vehicleAsset.vehicleVariantId,
          ),
          {
            ...vehicleAsset,
          },
        ]);
      },
    [],
  );

  return {
    syncOriginalAssetFileToFileStorage,
    uploadAssetFileToFileStorage,
    storeAssetReference,
    uploadAndSyncFileToFloorplan,
  };
};
