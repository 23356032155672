import { useSetRecoilState } from 'recoil';
import { useCallback } from 'react';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';

import { CommissioningLayout, Gate, LocalizationMapType } from '../helpers/types';
import { useGateStore, useLayoutStore } from '../store';
import { useApi } from './useApi';

export const useSaveData = () => {
  const { create, deleteLocalization, update, uploadLocalizationMapFile } = useApi();
  const showNotificationFn = useSetRecoilState(showNotification);

  const getPayload = useCallback((): CommissioningLayout => {
    const { delta, deltaAngle } = useLayoutStore.getState();
    const { adjustments } = useGateStore.getState();
    const gates: Gate[] = Array.from(adjustments.entries()).map(([id, position]) => ({
      id,
      delta: position,
      deltaAngle: 0,
    }));

    return {
      layoutDelta: {
        delta,
        deltaAngle,
      },
      gates,
      splines: [],
      localizationMaps: [],
    };
  }, []);

  const save = useCallback(async () => {
    const payload = getPayload();
    try {
      await update(payload);
    } catch (error) {
      if (error.response?.status === 404) {
        await create(payload);
        return;
      }

      showNotificationFn({
        type: NOTIFICATION_TYPES.ERROR,
        message: `Failed to save`,
      });
      console.error(error);
    }
  }, [create, getPayload, showNotificationFn, update]);

  const saveLocalizationMap = useCallback(
    async (type: LocalizationMapType, file: File) => {
      try {
        await uploadLocalizationMapFile(type, file);
      } catch (error) {
        if (error.response?.status === 404) {
          await create(getPayload());
          await uploadLocalizationMapFile(type, file);
          return;
        }

        showNotificationFn({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Failed to save`,
        });
      }
    },
    [create, getPayload, showNotificationFn, uploadLocalizationMapFile],
  );

  const deleteLocalizationMap = useCallback(
    async (type: LocalizationMapType) => {
      try {
        await deleteLocalization(type);
      } catch (error) {
        showNotificationFn({
          type: NOTIFICATION_TYPES.ERROR,
          message: `Failed to delete`,
        });
      }
    },
    [deleteLocalization, showNotificationFn],
  );
  return { deleteLocalizationMap, save, saveLocalizationMap };
};
