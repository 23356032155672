import { lazy } from 'react';
import { RecoilRoot } from 'recoil';
import RecoilNexus from 'recoil-nexus';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

import { LoginPage, RedirectToPortal } from '@/modules/authentication/components';
import { NotificationPopup } from '@/modules/notifications';
import { ModalManager } from '@common/components/ModalManager';
import { Lazy } from './modules/common/components/Lazy/Lazy';
import { theme } from './modules/common/components/theme';
import { FloorplanAdmin } from './modules/floorplanAdmin';
import { useApp } from '@/modules/core/hooks/useApp';

const Workspace = lazy(() => import('./modules/core/components/DigitalTwin'));
const DigitalTwin = (
  <Lazy key='Workspace'>
    <RecoilRoot>
      <RecoilNexus />
      <Workspace />
      <ModalManager />
      <NotificationPopup />
    </RecoilRoot>
  </Lazy>
);
const FloorPlanAdmin = (
  <Lazy key='FloorplanAdmin'>
    <RecoilRoot>
      <RecoilNexus />
      <FloorplanAdmin />
      <ModalManager />
      <NotificationPopup />
    </RecoilRoot>
  </Lazy>
);

export function App() {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  useApp();

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Routes>
          {!isAuthenticated &&
            [InteractionStatus.None, InteractionStatus.SsoSilent].includes(inProgress) && (
              <Route path='*' element={<LoginPage />} />
            )}
          {isAuthenticated && (
            <Route>
              <Route path='/:projectId'>
                <Route path='' element={FloorPlanAdmin} />
                <Route path='builder/:groupId/version/:floorPlanId' element={DigitalTwin} />
                <Route
                  path='builder/:groupId/version/:floorPlanId/tracker/:simulationId/floorPlan/:floorPlanVersionId'
                  element={DigitalTwin}
                />
                <Route path='*' element={<Navigate to='' replace />} />
              </Route>
              <Route path='*' element={<RedirectToPortal />} />
            </Route>
          )}
        </Routes>
      </ThemeProvider>
    </>
  );
}
