import { hideNotification, notificationAtom } from '@/store/recoil/notification';
import { Alert, Snackbar, ThemeProvider, createTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { WorkspaceOverlayPosition } from '../../../components/WorkspaceUi';
import { NotificationsContext, defaultTimeout } from '../constants';
import { notificationsContextState } from '../store/state';

export const theme = createTheme();

export function NotificationPopup() {
  const [open, setOpen] = useState(false);
  const [timeout, setTimeout] = useState(defaultTimeout);
  const [activeNotification, setActiveNotification] = useState<JSX.Element>();
  const { queue } = useRecoilValue(notificationAtom);
  const [, hideNotificationAction] = useRecoilState(hideNotification);
  const activeNotificationId = useRef<string>();
  const notificationsContext = useRecoilValue(notificationsContextState);

  useEffect(() => {
    const hasNotification = queue && queue.length > 0;

    if (hasNotification !== open) setOpen(hasNotification);

    if (hasNotification) {
      const notification = queue[0];

      // Prevent rerender
      if (notification.id === activeNotificationId.current) return;

      activeNotificationId.current = notification.id;
      setTimeout(notification.timeOut ?? defaultTimeout);

      const messages = notification.message.split('\n');
      setActiveNotification(
        <Alert
          action={<ThemeProvider theme={theme}>{notification.action}</ThemeProvider>}
          severity={notification.type}
          icon={notification.timeOut ? <CircularProgress size={16} /> : false}
          elevation={2}
          sx={{
            borderRadius: '4px',
          }}
        >
          {messages.map((text) => (
            <div key={`${notification.id}-${text}`}>{text}</div>
          ))}
        </Alert>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue]);

  // event?: React.SyntheticEvent | Event, reason?: string
  const handleClose = useCallback(() => {
    hideNotificationAction(activeNotificationId.current);
  }, [hideNotificationAction]);

  if (notificationsContext === NotificationsContext.FLOORPLAN_ADMIN) {
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        autoHideDuration={timeout}
        onClose={handleClose}
      >
        {activeNotification}
      </Snackbar>
    );
  }

  return (
    <WorkspaceOverlayPosition
      alignVertical='top'
      alignHorizontal='right'
      additionnalStyles={{
        display: 'flex',
        width: '400px',
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={timeout}
        onClose={handleClose}
        style={{ top: 0, right: 0 }}
        sx={{ '&:hover': { cursor: 'default' } }}
      >
        {activeNotification}
      </Snackbar>
    </WorkspaceOverlayPosition>
  );
}
