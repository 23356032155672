import { useCallback } from 'react';
import { Node } from 'konva/lib/Node';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { isArea, isGroupable, supportsVehicleTypes } from '@/modules/common/helpers/shapes';
import {
  GroupComposition,
  ShapeGroupInteractivityMode,
  SingleTypeGroup,
  LassieGroup,
  ShapeGroup,
  TemplateGroupGenerationParams,
} from '@/modules/common/types/shapeGroup';
import { ShapeType } from '@/modules/common/types/shapes';
import { TemplateType } from '@/modules/common/types/templating';
import { useFlow } from '@/modules/flows/hooks/useFlow';
import { formatShapeType } from '@/store/recoil/floorplan/helper';
import { selectedShapesIdsState, selectedShapesState } from '@/store/recoil/shapes/selected';
import { HistoryManager } from '@recoil/history';
import { NOTIFICATION_TYPES, showNotification } from '../../../store/recoil/notification';
import { useGetRelatedFlowIdsOfShapeIds } from '../../flows/hooks/useGetRelatedFlowIdsOfShapeIds';
import { allGroupIdsState, selectedGroupIdsState, shapeGroupState } from '../atom';
import { generateNewGroupName } from '../helpers';
import { allGroupsSelector } from '../selectors';
import { AreaShape, DTShape } from '@recoil/shape';
import { isUnique } from '@modules/common/helpers/array';
import { UndoRedoState, useUndoRedoConnections } from '@/modules/connections';
import { allShapesSelector } from '@/store/recoil/shapes';
import { useArtefacts } from '@/modules/artefacts';

const findGroupIdInParent = (thisNode: Node, shapeGroupIds: Set<string>): string => {
  if (!thisNode || thisNode.getType() === 'Stage') return null;
  const thisId = thisNode.id();
  if (shapeGroupIds.has(thisId)) return thisId;
  return findGroupIdInParent(thisNode.getParent(), shapeGroupIds);
};

export const useShapeGroup = () => {
  const { t } = useTranslation();
  const showNotificationFn = useSetRecoilState(showNotification);
  const getRelatedFlowIds = useGetRelatedFlowIdsOfShapeIds();
  const { getRelatedFlowIdsOfGroup, deleteFlows } = useFlow();
  const { getUndoRedoState, restoreUndoRedoState } = useUndoRedoConnections();
  const { update: updateArtefacts } = useArtefacts();

  const updateGroup = useRecoilCallback(
    ({ set }) =>
      (shapeGroups: ShapeGroup[]) => {
        const newIds: string[] = [];
        shapeGroups.forEach((group) => {
          set(shapeGroupState(group.id), group);
          newIds.push(group.id);
        });
        set(allGroupIdsState, (ids) => [...new Set([...ids, ...newIds])]);
      },
    [],
  );

  const undoRedoGroup = useRecoilCallback(
    ({ set }) =>
      (shapeGroups: ShapeGroup[]) => {
        updateGroup(shapeGroups);
        set(
          allGroupIdsState,
          shapeGroups.map((shapeGroup) => shapeGroup.id),
        );
      },
    [updateGroup],
  );

  const findParentShapeGroupId = useRecoilCallback(
    ({ snapshot }) =>
      async (node: Node) => {
        const shapeGroupIds = new Set(await snapshot.getPromise(allGroupIdsState));
        return findGroupIdInParent(node, shapeGroupIds);
      },
    [],
  );

  const findShapeGroupId = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeId: string): Promise<string> => {
        if (!shapeId) return null;
        const shapeGroups = await snapshot.getPromise(allGroupsSelector);
        for (let i = 0; i < shapeGroups.length; ++i) {
          const shapeGroup = shapeGroups[i];
          if (shapeGroup.children.indexOf(shapeId) > -1) return shapeGroup.id;
        }
        return null;
      },
    [],
  );

  const createSingleTypeGroupFromSelectedShapes = useRecoilCallback(
    ({ snapshot, set }) =>
      async (shapeType: ShapeType): Promise<ShapeGroup> => {
        if (!isGroupable(shapeType)) return;
        const selectedShapes = await snapshot.getPromise(selectedShapesState);
        const shapesToGroup = selectedShapes.filter(
          (item) => formatShapeType(item.type) === shapeType,
        );

        const relatedFlows = await getRelatedFlowIds(shapesToGroup.map((shape) => shape.id));
        if (relatedFlows.length > 0) {
          showNotificationFn({
            type: NOTIFICATION_TYPES.ERROR,
            message: t(
              'errors:grouping.grouping_flow_related_shapes',
              'Please remove any related flows before creating a group. Once the group is created, flows can be added to the group.',
            ),
          });
          return;
        }

        if (supportsVehicleTypes(shapeType)) {
          const vehicleTypes = selectedShapes
            .filter((shape) => isArea(shape.type) && supportsVehicleTypes(shape.type))
            .flatMap((shape: AreaShape) => shape.parameters.supportedVehicleIds);

          if (!isUnique(vehicleTypes)) {
            showNotificationFn({
              type: NOTIFICATION_TYPES.ERROR,
              message: t(
                'errors:grouping.common_vehicle_type',
                'Please set a common vehicle type before creating a group.',
              ),
            });
            return;
          }
        }

        const newGroupId = uuid();
        const children = shapesToGroup.map((shape) => shape.id);
        const newGroup: SingleTypeGroup = {
          id: newGroupId,
          name: generateNewGroupName(shapeType),
          composition: GroupComposition.SINGLE_TYPE,
          children,
          type: shapeType,
          interactivityMode: ShapeGroupInteractivityMode.UNRESTRICTED,
        };
        updateGroup([newGroup]);
        set(selectedGroupIdsState, [newGroupId]);

        const oldGroups = await snapshot.getPromise(allGroupsSelector);
        const groups = [...oldGroups, newGroup];
        HistoryManager.track(`group created`, groups, oldGroups, undoRedoGroup);
        return newGroup;
      },
    [getRelatedFlowIds, showNotificationFn, t, undoRedoGroup, updateGroup],
  );

  const createSingleTypeGroupFromShapeIds = useRecoilCallback(
    ({ snapshot, set }) =>
      async (newGroupType: ShapeType, shapesIds: string[]): Promise<ShapeGroup> => {
        if (shapesIds.length === 0) {
          return;
        }

        const newGroupId = uuid();
        const newGroup: SingleTypeGroup = {
          id: newGroupId,
          name: generateNewGroupName(newGroupType),
          composition: GroupComposition.SINGLE_TYPE,
          children: shapesIds,
          type: newGroupType,
          interactivityMode: ShapeGroupInteractivityMode.UNRESTRICTED,
        };
        set(shapeGroupState(newGroupId), newGroup);
        set(allGroupIdsState, (ids) => [newGroupId, ...ids]);

        const oldGroups = await snapshot.getPromise(allGroupsSelector);
        const groups = [...oldGroups, newGroup];
        HistoryManager.track(`group created`, groups, oldGroups, undoRedoGroup);
        return newGroup;
      },
    [undoRedoGroup],
  );

  const createTemplateGroupFromShapeIds = useRecoilCallback(
    ({ snapshot, set }) =>
      async <T extends TemplateType>(
        templateType: T,
        shapesIds: string[],
        usedInput: TemplateGroupGenerationParams[T],
      ): Promise<LassieGroup> => {
        if (shapesIds.length === 0) {
          return;
        }

        const newGroupId = uuid();
        const newGroup: LassieGroup = {
          type: templateType,
          id: newGroupId,
          name: usedInput.name,
          composition: GroupComposition.MIXED,
          children: shapesIds,
          interactivityMode: ShapeGroupInteractivityMode.LOCKED_SELECTABLE,
          generationParams: {
            ...usedInput,
          },
        };
        set(shapeGroupState(newGroupId), newGroup);
        set(allGroupIdsState, (ids) => [newGroupId, ...ids]);

        const oldGroups = await snapshot.getPromise(allGroupsSelector);
        const newGroups = [...oldGroups, newGroup];

        // undo redo
        const setSelfCallback = async ({
          groups,
          shapes,
          connectionsState,
        }: {
          groups: ShapeGroup[];
          shapes: DTShape[];
          connectionsState?: UndoRedoState;
        }) => {
          set(selectedShapesIdsState, (currentSelectedIds) =>
            currentSelectedIds.every((selectedId) => shapes.find((item) => item.id === selectedId))
              ? currentSelectedIds
              : [],
          );
          set(selectedGroupIdsState, (currentSelectedIds) =>
            currentSelectedIds.every((selectedId) => groups.find((item) => item.id === selectedId))
              ? currentSelectedIds
              : [],
          );

          set(allShapesSelector, shapes);
          set(allGroupsSelector, groups);

          if (connectionsState) restoreUndoRedoState(connectionsState);

          updateArtefacts(shapes.map((item) => item.id));
        };

        const newShapes = await snapshot.getPromise(allShapesSelector);
        const previousShapes = newShapes.filter((item) => !shapesIds.includes(item.id));
        const { newConnectionState, oldConnectionState } = await getUndoRedoState(shapesIds);

        HistoryManager.track(
          `Template group created`,
          { groups: newGroups, shapes: newShapes, connectionsState: oldConnectionState },
          { groups: oldGroups, shapes: previousShapes, connectionsState: newConnectionState },
          setSelfCallback,
        );
        return newGroup;
      },
    [getUndoRedoState, restoreUndoRedoState, updateArtefacts],
  );

  const deleteGroups = useRecoilCallback(
    ({ set }) =>
      async (groupIds: string[]) => {
        if (groupIds.length === 0) return;

        // deselect all shapes / groups
        set(selectedShapesIdsState, []);
        set(selectedGroupIdsState, []);

        // delete flow of this group
        let flowIds = await Promise.all(
          groupIds.map((groupId) => getRelatedFlowIdsOfGroup(groupId)),
        );
        let flowIdsFlat = flowIds.flat();
        deleteFlows(flowIdsFlat);

        // update shape group ids collection
        set(allGroupIdsState, (prev) => {
          const ids = new Set(prev);
          groupIds.forEach((id) => ids.delete(id));
          return Array.from(ids);
        });
      },
    [deleteFlows, getRelatedFlowIdsOfGroup],
  );

  const deleteShapeGroupOfShapeIds = useCallback(
    async (shapeIds: string[]) => {
      const groupIds = new Set<string>();
      const promises = shapeIds.map((shapeId) => findShapeGroupId(shapeId));
      const results = await Promise.all(promises);
      results.forEach((groupId) => {
        if (groupId) groupIds.add(groupId);
      });
      await deleteGroups(Array.from(groupIds));
    },
    [deleteGroups, findShapeGroupId],
  );

  const getAllShapeIds = useRecoilCallback(
    ({ snapshot }) =>
      async (shapeGroupIds: string[]): Promise<Set<string>> => {
        const promises = shapeGroupIds.map((id) => snapshot.getPromise(shapeGroupState(id)));
        const results = await Promise.all(promises);
        const shapeIds = new Set(results.flatMap((shapeGroup) => shapeGroup.children));
        return shapeIds;
      },
    [],
  );

  return {
    createSingleTypeGroupFromSelectedShapes,
    createSingleTypeGroupFromShapeIds,
    createTemplateGroupFromShapeIds,
    updateGroup,
    deleteGroups,
    deleteShapeGroupOfShapeIds,
    findParentShapeGroupId,
    findShapeGroupId,
    getAllShapeIds,
  };
};
