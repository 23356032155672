import { usePIMApi } from '@/modules/api/hooks';
import { LoadCarrierType } from '@modules/common/types/floorPlan';
import REST from '../types';
import { useRecoilCallback } from 'recoil';
import { availableLoadCarrierTypesState } from '@recoil/loadCarrierTypes';
import { createLoadCarrierId } from '@modules/loadCarriers';

export const useLoadCarrierService = () => {
  const pimServiceApi = usePIMApi();

  const getAllLoadCarriers = async (): Promise<LoadCarrierType[]> => {
    try {
      const response = await pimServiceApi.get<REST.LoadCarrier[]>('/v1/loadCarriers');
      if (response.status !== 200) {
        console.log(`Error fetching load carriers: ${response.statusText}`);
        return [];
      }

      return response.data.map((v) => convertRestLoadCarrierToModel(v));
    } catch (e) {
      console.log('Error fetching load carriers: ', e);
      return [];
    }
  };

  const getLoadCarrier = async (databaseId: number): Promise<LoadCarrierType | null> => {
    try {
      const response = await pimServiceApi.get<REST.LoadCarrier>(`/v1/loadCarriers/${databaseId}`);
      if (response.status !== 200) {
        console.log(
          `Error fetching load carrier with id: ${databaseId}, status: ${response.statusText}`,
        );
        return null;
      }
      return convertRestLoadCarrierToModel(response.data);
    } catch (e) {
      console.log(`Error fetching load carrier with id: ${databaseId}, error: ${e}`);
      return null;
    }
  };

  const getLoadTypeById = useRecoilCallback(
    ({ snapshot }) =>
      async (id: string) => {
        const loadCarrierTypes = await snapshot.getPromise(availableLoadCarrierTypesState);
        return loadCarrierTypes.find((item) => item.id === id);
      },
    [],
  );
  const convertRestLoadCarrierToModel = (v: REST.LoadCarrier): LoadCarrierType => ({
    height: v.height,
    width: v.width,
    databaseId: v.id,
    id: createLoadCarrierId(v.name),
    length: v.length,
    name: v.name,
    safeWorkingLoad: v.safeWorkLoad,
    weight: v.weight,
    image: {
      height: v.image.height,
      width: v.image.width,
      url: v.image.url,
    },
  });

  return { getAllLoadCarriers, getLoadCarrier, getLoadTypeById };
};
