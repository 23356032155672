import { v4 as uuid } from 'uuid';
import { referenceFile } from '@/modules/referenceImage/store';
import { KEYCODE } from '@/store/recoil/input';
import { TextInput } from '@common/components/inputs';
import { Dialog, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigation } from '../common/hooks';
import { Module } from '../common/types/navigation';
import { isCreatorOpenState } from './store';
import { navSelector } from '../../store/recoil/nav';
import { UserPreferenceName } from '@modules/userPreferences';
import { useUserPreference } from '@modules/userPreferences/hooks';
import { Theme } from '@modules/common/types/general';
import Label from '../common/components/Label';
import { useFloorPlanGroupApi } from '@modules/api/hooks';
import { useParams } from 'react-router-dom';
import { NOTIFICATION_TYPES, showNotification } from '@recoil/notification';

export default function FloorplanCreator() {
  const { projectId } = useParams();
  const { create } = useFloorPlanGroupApi();
  const [textInput, setTextInput] = useState('');
  const setDwg = useSetRecoilState(referenceFile);
  const { t } = useTranslation(['interface', 'common']);
  const buttonRef = useRef(null);
  const { goToBuilder } = useNavigation();
  const setNav = useSetRecoilState(navSelector);
  const [isCreatorOpen, setIsCreatorOpen] = useRecoilState(isCreatorOpenState);
  const showNotificationFn = useSetRecoilState(showNotification);

  const theme = useUserPreference(UserPreferenceName.GENERAL_THEME);

  const handleClose = useCallback(() => setIsCreatorOpen(false), [setIsCreatorOpen]);

  const onCreateClick = useCallback(async () => {
    try {
      const group = await create(projectId, textInput);
      goToBuilder(group.id, group.floorPlanId);
      setNav(Module.SETUP);
      setDwg(null);
    } catch (e) {
      showNotificationFn({
        id: uuid(),
        type: NOTIFICATION_TYPES.ERROR,
        message: t('interface:home.create_floorplan.notification.failure'),
      });
    }
  }, [t, showNotificationFn, projectId, create, setDwg, textInput, goToBuilder, setNav]);

  const handleTextInputChange = useCallback((value) => {
    setTextInput(value);
  }, []);

  const keyUpHandler = useCallback((keyboardEvent) => {
    if (keyboardEvent.key === KEYCODE.ENTER) {
      if (buttonRef?.current && !buttonRef.current.disabled) {
        buttonRef.current.click();
      }
    }
  }, []);

  useEffect(() => {
    if (isCreatorOpen) {
      window.addEventListener('keyup', keyUpHandler);
    } else {
      window.removeEventListener('keyup', keyUpHandler);
    }
  }, [isCreatorOpen, keyUpHandler]);

  useEffect(() => {
    if (isCreatorOpen) setTextInput('');
  }, [isCreatorOpen, setIsCreatorOpen]);

  useEffect(() => () => setIsCreatorOpen(false), [setIsCreatorOpen]);

  return (
    <Dialog
      onClose={handleClose}
      open={isCreatorOpen}
      className={theme === Theme.DARK ? 'dark' : ''}
    >
      <Stack gap={2} p={2}>
        <Typography variant='h6' align='center'>
          {t('interface:home.create_new_floorplan', 'Create new floorplan')}
        </Typography>
        <Label label={t('interface:home.name', 'Name')}>
          <TextInput value={textInput} onChange={handleTextInputChange} autoFocus />
        </Label>
        <Stack gap={1}>
          <Button
            ref={buttonRef}
            variant='secondary'
            onClick={onCreateClick}
            disabled={textInput.length < 1}
          >
            {t('interface:home.create_button', 'Duplicate Floorplan')}
          </Button>
          <Button onClick={handleClose}>{t('common:cancel', 'Cancel')}</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
