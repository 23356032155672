import { AxiosResponse } from 'axios';
import { useEffect, useMemo } from 'react';

import { useAxios } from './useAxios';
import { useConfig } from '@/modules/common/hooks';
import {DTException} from "@/modules/common/helpers/DTException";

export const useFloorPlanServiceApi = () => {
  const { api } = useConfig();

  const scopes = useMemo(() => [api.floorPlanService.scope], [api.floorPlanService.scope]);
  const axios = useAxios({
    baseURL: api.floorPlanService.url,
    scopes,
  });

  useEffect(() => {
    axios.interceptors.response.use((value: AxiosResponse<any, any>) => {
      if (value.data.type === 'FAULT') {
        throw new DTException('Floorplan service fault.', {
          code: value.data.value.map((detail) => detail.code),
          cause: value.data.value
        })
      }

      return value;
    });
  }, [axios]);

  return axios;
};
