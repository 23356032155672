import { SvgIcon } from '@mui/material';

const DownloadIcon = ({ className = '', ...rest }) => (
    <SvgIcon
        width='15'
        height='15'
        viewBox='0 0 15 15'
        style={{ fill: 'currentColor', stroke: 'none' }}
        xmlns='http://www.w3.org/2000/svg'
        className={className}
        {...rest}
    >
        <path
            d="M2.11537 15.0004C1.65512 15.0004 1.27083 14.8463 0.9625 14.5379C0.654167 14.2296 0.5 13.8453 0.5 13.3851V11.0004H1.5V13.3851C1.5 13.5389 1.5641 13.6799 1.6923 13.8081C1.82052 13.9363 1.96154 14.0004 2.11537 14.0004H12.8846C13.0385 14.0004 13.1795 13.9363 13.3077 13.8081C13.4359 13.6799 13.5 13.5389 13.5 13.3851V11.0004H14.5V13.3851C14.5 13.8453 14.3458 14.2296 14.0375 14.5379C13.7292 14.8463 13.3449 15.0004 12.8846 15.0004H2.11537ZM7.5 11.2312L3.96152 7.69275L4.66923 6.97352L7 9.3043V0.654297H8V9.3043L10.3308 6.97352L11.0385 7.69275L7.5 11.2312Z" fill="#2537F3"
        />
    </SvgIcon>
);
export default DownloadIcon;