import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Vector2 } from 'three';

import { useOnShapeAngleChange } from '@/components/PropertiesPanel/hooks/useOnShapeAngleChange';
import { useArtefacts } from '@/modules/artefacts';
import { isHighwayShape, isObstacleShape, isPointsShape, isProcessAreaTwoEp, isWallShape } from '@/modules/common/types/guards';
import { ControlPoint } from '@/modules/common/types/shapes';
import { useConnections } from '@/modules/connections';
import {
  filterNonEditableGroups,
  selectedGroupIdsState,
  selectedGroupsSelector,
} from '@/modules/shapeGroups';
import { makePropertyTrackerMap } from '@/modules/workspace/helpers/dragging';
import { PropertyPath, PropertyTrackerMap } from '@/modules/workspace/types/dragging';
import { Accordion } from '@common/components/Accordion';
import { Dimensions } from '@common/components/Dimensions';
import { clampAngle180 } from '@modules/common/helpers/math';
import { useDragAndResizeHistory, useUnitSync } from '@modules/common/hooks';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { useFloorPlanState } from '@modules/floorplan/hooks/useFloorPlanState';
import {
  PreValidationAspect,
  PrevalidationDebounceMs,
  useRunPreValidation,
} from '@modules/floorplanValidation/clientSide';
import { CANVAS_TO_SHAPE_SCALE, SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { CONTEXT, contextState } from '@recoil/input';
import { selectedShapesIdsState, selectedShapesState } from '@recoil/shapes/selected';
import {
  shapesAngleState,
  shapesHeightState,
  shapesPositionState,
  shapesWidthState,
} from './store/dimension';
import { PanelDisplayMode, hasSomePositions, panelModeState } from './store/panel';
import { orientedLoadCarriersBoundingBox } from '@/store/recoil/loadCarrierTypes';
import { getMinimumSize } from '@/modules/floorplan/constants/constants';
import { vehicleDetailsState } from '@/modules/vehicles/store/vehicleDetailsState';

const ShapeDimensions = () => {
  const { t } = useTranslation();
  return (
    <Accordion
      title={t('interface:properties.dimensions.label', 'Dimensions')}
      unmountOnExit
      defaultExpanded
      sx={{ px: 2 }}
    >
      <ShapeDimensionsContent />
    </Accordion>
  );
};

function ShapeDimensionsContent() {
  const panel = useRecoilValue(panelModeState);
  const shapes = useRecoilValue(selectedShapesState);
  const groups = useRecoilValue(selectedGroupsSelector);
  const setContext = useSetRecoilState(contextState);
  const { updateConnections } = useConnections();
  const selectedGroups = useRecoilValue(selectedGroupIdsState);
  const { trackDragAndResizeHistory } = useDragAndResizeHistory();
  const { toBaseUnit } = useUnitSync();

  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation(PrevalidationDebounceMs.INPUT);
  const [{ x, y }] = useRecoilState(shapesPositionState);
  const [width] = useRecoilState(shapesWidthState);
  const [height] = useRecoilState(shapesHeightState);
  const [angle] = useRecoilState(shapesAngleState);
  const mode = useRecoilValue(modeSelector);
  const { onShapeAngleChange, onUnmountAngleChange } = useOnShapeAngleChange();
  const { updateDebounced: updateArtefacts, showLoader: showArtefactsLoader } = useArtefacts();

  const disabled = mode !== WorkspaceMode.EDITABLE || filterNonEditableGroups(groups).length > 0;
  const sizeDisabled =
    shapes.length !== 1 ||
    panel === PanelDisplayMode.ANGLED_HIGHWAYS ||
    panel === PanelDisplayMode.WALLS ||
    panel === PanelDisplayMode.POSITIONS ||
    (panel === PanelDisplayMode.ELEMENTS && hasSomePositions(shapes)) ||
    selectedGroups.length > 0;

  const onHeightChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (value: number) => {
        try {
          const selectedShapes = await snapshot.getPromise(selectedShapesState);
          const currentValue = await snapshot.getPromise(shapesHeightState);
          const currentPropertyMap = makePropertyTrackerMap(selectedShapes);

          if (value === currentValue) return;

          const shape = selectedShapes[0];

          if (isHighwayShape(shape)) {
            const vehicle = await snapshot.getPromise(vehicleDetailsState(shape.parameters.supportedVehicleIds[0]));
            const minSize = getMinimumSize(
              shape, 
              vehicle,
            )

            if (value < minSize.height) {
              throw new Error(`The minimum height for this shape type is ${minSize.height}`);
            }
          } else if (!isObstacleShape(shape) && !isWallShape(shape) && !isProcessAreaTwoEp(shape)) {
            const vehicle = await snapshot.getPromise(vehicleDetailsState(shape.parameters.supportedVehicleIds[0]));
            const loadCarrierBoundingBox = await snapshot.getPromise(orientedLoadCarriersBoundingBox(shape.id))
            const minSize = getMinimumSize(
              shape, 
              vehicle,
              {width: loadCarrierBoundingBox.width * CANVAS_TO_SHAPE_SCALE, length: loadCarrierBoundingBox.length * CANVAS_TO_SHAPE_SCALE}
            )

            if (value < minSize.height) {
              throw new Error(`The minimum height for this shape type is ${minSize.height}`);
            }
          }
          
          showArtefactsLoader(selectedShapes.map((item) => item.id));
          set(shapesHeightState, value);

          const newPropertyMap: PropertyTrackerMap = new Map();
          currentPropertyMap.forEach((propertyMapEntry, key) => {
            if (propertyMapEntry.path !== PropertyPath.PROPERTIES) return;

            newPropertyMap.set(key, {
              ...propertyMapEntry,
              boundingBox: {
                ...propertyMapEntry.boundingBox,
                height: toBaseUnit(value) * SHAPE_TO_CANVAS_SCALE,
              },
            });
          });

          // TODO: support angled highways
          trackDragAndResizeHistory('resized', newPropertyMap, currentPropertyMap);

          const shapeIds = [...newPropertyMap.keys()];
          updateConnections(shapeIds);
          await saveFloorPlan();
          updateArtefacts(shapeIds);
          runPreValidation([
            PreValidationAspect.REQUIRED_ELEMENTS,
            PreValidationAspect.FLOWLESS_AREAS,
          ]);
        } catch (e) {
          return { error: e.message };
        }
      },
    [
      runPreValidation,
      saveFloorPlan,
      trackDragAndResizeHistory,
      updateConnections,
      toBaseUnit,
      updateArtefacts,
      showArtefactsLoader,
    ],
  );

  const onWidthChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (value: number) => {
        try {
          const selectedShapes = await snapshot.getPromise(selectedShapesState);
          const currentValue = await snapshot.getPromise(shapesWidthState);
          const currentPropertyMap = makePropertyTrackerMap(selectedShapes);

          if (value === currentValue) return;

          const shape = selectedShapes[0];

          if (isHighwayShape(shape)) {
            const vehicle = await snapshot.getPromise(vehicleDetailsState(shape.parameters.supportedVehicleIds[0]));
            const minSize = getMinimumSize(
              shape, 
              vehicle,
            )

            if (value < minSize.width) {
              throw new Error(`The minimum width for this shape type is ${minSize.width}`);
            }
          } else if (!isObstacleShape(shape) && !isWallShape(shape) && !isProcessAreaTwoEp(shape)) {
            const vehicle = await snapshot.getPromise(vehicleDetailsState(shape.parameters.supportedVehicleIds[0]));
            const loadCarrierBoundingBox = await snapshot.getPromise(orientedLoadCarriersBoundingBox(shape.id))
            const minSize = getMinimumSize(
              shape, 
              vehicle,
              {width: loadCarrierBoundingBox.width * CANVAS_TO_SHAPE_SCALE, length: loadCarrierBoundingBox.length * CANVAS_TO_SHAPE_SCALE}
            )

            if (value < minSize.width) {
              throw new Error(`The minimum width for this shape type is ${minSize.width}`);
            }
          }

          showArtefactsLoader(selectedShapes.map((item) => item.id));
          set(shapesWidthState, value);

          const newPropertyMap: PropertyTrackerMap = new Map();
          currentPropertyMap.forEach((propertyMapEntry, key) => {
            if (propertyMapEntry.path !== PropertyPath.PROPERTIES) return;

            newPropertyMap.set(key, {
              ...propertyMapEntry,
              boundingBox: {
                ...propertyMapEntry.boundingBox,
                width: toBaseUnit(value) * SHAPE_TO_CANVAS_SCALE,
              },
            });
          });

          // TODO: support angled highways
          trackDragAndResizeHistory('resized', newPropertyMap, currentPropertyMap);
          const shapeIds = [...newPropertyMap.keys()];
          updateConnections(shapeIds);
          await saveFloorPlan();
          updateArtefacts(shapeIds);
          runPreValidation([
            PreValidationAspect.REQUIRED_ELEMENTS,
            PreValidationAspect.FLOWLESS_AREAS,
          ]);
        } catch (e) {
          return { error: e.message };
        }
      },
    [
      runPreValidation,
      saveFloorPlan,
      trackDragAndResizeHistory,
      updateConnections,
      toBaseUnit,
      updateArtefacts,
      showArtefactsLoader,
    ],
  );

  const onXChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (value: number) => {
        const selectedShapes = await snapshot.getPromise(selectedShapesState);
        const { x: currentValue } = await snapshot.getPromise(shapesPositionState);
        const currentPropertyMap = makePropertyTrackerMap(selectedShapes);

        if (value === currentValue) return;

        set(shapesPositionState, { x: value });

        const delta = new Vector2(value - currentValue, 0);
        const newPropertyMap: PropertyTrackerMap = new Map();
        currentPropertyMap.forEach((propertyMapEntry, key) => {
          if (propertyMapEntry.path === PropertyPath.PROPERTIES_CONTROLPOINTS) {
            const newControlPoints = propertyMapEntry.value.map(
              (item: ControlPoint): ControlPoint => {
                const newPosition = new Vector2(item.position.x, item.position.y)
                  .clone()
                  .add(delta);

                return {
                  ...item,
                  position: newPosition,
                };
              },
            );

            newPropertyMap.set(key, {
              ...propertyMapEntry,
              value: newControlPoints,
            });

            return;
          }

          newPropertyMap.set(key, {
            ...propertyMapEntry,
            boundingBox: {
              ...propertyMapEntry.boundingBox,
              x: propertyMapEntry.boundingBox.x + toBaseUnit(delta.x) * SHAPE_TO_CANVAS_SCALE,
            },
          });
        });

        trackDragAndResizeHistory('moved', newPropertyMap, currentPropertyMap);
        updateConnections([...newPropertyMap.keys()]);
        await saveFloorPlan();
        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.FLOWLESS_AREAS,
        ]);
      },
    [runPreValidation, saveFloorPlan, trackDragAndResizeHistory, updateConnections, toBaseUnit],
  );

  const onYChange = useRecoilCallback(
    ({ set, snapshot }) =>
      async (value: number) => {
        const selectedShapes = await snapshot.getPromise(selectedShapesState);
        const { y: currentValue } = await snapshot.getPromise(shapesPositionState);
        const currentPropertyMap = makePropertyTrackerMap(selectedShapes);

        if (value === currentValue) return;

        set(shapesPositionState, { y: value });

        const delta = new Vector2(0, value - currentValue);

        const newPropertyMap: PropertyTrackerMap = new Map();
        currentPropertyMap.forEach((propertyMapEntry, key) => {
          if (propertyMapEntry.path === PropertyPath.PROPERTIES_CONTROLPOINTS) {
            const newControlPoints = propertyMapEntry.value.map(
              (item: ControlPoint): ControlPoint => {
                const newPosition = new Vector2(item.position.x, item.position.y)
                  .clone()
                  .add(delta);

                return {
                  ...item,
                  position: newPosition,
                };
              },
            );

            newPropertyMap.set(key, {
              ...propertyMapEntry,
              value: newControlPoints,
            });

            return;
          }

          newPropertyMap.set(key, {
            ...propertyMapEntry,
            boundingBox: {
              ...propertyMapEntry.boundingBox,
              y: propertyMapEntry.boundingBox.y + toBaseUnit(delta.y) * SHAPE_TO_CANVAS_SCALE,
            },
          });
        });

        trackDragAndResizeHistory('moved', newPropertyMap, currentPropertyMap);
        updateConnections([...newPropertyMap.keys()]);
        await saveFloorPlan();
        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.FLOWLESS_AREAS,
        ]);
      },
    [runPreValidation, saveFloorPlan, trackDragAndResizeHistory, updateConnections, toBaseUnit],
  );

  const onBlur = useCallback(() => {
    setContext(CONTEXT.WORKSPACE);
  }, [setContext]);

  const onFocus = useCallback(() => {
    setContext(CONTEXT.PROPERTYPANEL);
  }, [setContext]);

  const onAngleChange = useRecoilCallback(
    ({ snapshot }) =>
      async (newValue: number) => {
        const selectedShapeIds = await snapshot.getPromise(selectedShapesIdsState);

        showArtefactsLoader(selectedShapeIds);
        // TODO: support rotating multiple shapes through the angle input qround the center of the selection
        await onShapeAngleChange(newValue);

        updateConnections(selectedShapeIds);
        await saveFloorPlan();
        updateArtefacts(selectedShapeIds);
        runPreValidation([
          PreValidationAspect.REQUIRED_ELEMENTS,
          PreValidationAspect.FLOWLESS_AREAS,
        ]);
      },
    [updateConnections, saveFloorPlan, runPreValidation, onShapeAngleChange, showArtefactsLoader],
  );

  const onAngleBlur = useCallback(
    async (value) => {
      await onAngleChange(clampAngle180(value));
    },
    [onAngleChange],
  );

  const angleDisabled = useMemo(() => shapes.length > 1 || shapes.some(isPointsShape), [shapes]);

  useEffect(
    () => () => {
      onUnmountAngleChange(shapes.map((item) => item.id));
      saveFloorPlan();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Dimensions
      disabled={disabled}
      x={x}
      y={y}
      width={width}
      height={height}
      angle={angle}
      disableHeight={sizeDisabled}
      disableWidth={sizeDisabled}
      disableAngle={angleDisabled}
      onBlur={onBlur}
      onFocus={onFocus}
      onHeightChange={onHeightChange}
      onWidthChange={onWidthChange}
      onXChange={onXChange}
      onYChange={onYChange}
      onAngleChange={onAngleChange}
      onAngleBlur={onAngleBlur}
    />
  );
}

export default ShapeDimensions;
