import { Vector2d } from 'konva/lib/types';
import { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { BoundingBox } from '@/helpers/types';
import { useArtefacts } from '@/modules/artefacts';
import { useCanvasStore } from '@/modules/canvas';
import { COLORS } from '@/modules/commissioning/mappers/areas/colors';
import { useShapeCreationHistory } from '@/modules/common/hooks';
import { useNewShape } from '@/modules/common/hooks/useNewShape';
import { ShapeProperties, ShapeType } from '@/modules/common/types/shapes';
import { useConnections, useUndoRedoConnections } from '@/modules/connections';
import { useFloorPlanState } from '@/modules/floorplan';
import { getMinimumSizeByShapeType } from '@/modules/floorplan/constants/constants';
import { enabledLoadCarrierTypesSelector } from '@/store/recoil/loadCarrierTypes';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import shapeAtom from '@/store/recoil/shape/atom';
import { allShapesSelector } from '@/store/recoil/shapes';
import { selectedShapesIdsState } from '@/store/recoil/shapes/selected';
import { toolButtonState } from '@/store/recoil/tool';
import { drawingIdSelector, initialMousePositionSelector } from '@/store/recoil/workspace';
import workspaceAtom from '@/store/recoil/workspace/atom';
import { isAreaShape, isHighwayShape, isPositionShape } from '@modules/common/types/guards';
import { PreValidationAspect, useRunPreValidation } from '@modules/floorplanValidation/clientSide';
import { snappingState, useSnapping } from '@modules/snapping';
import { SHAPE_TO_CANVAS_SCALE } from '@modules/workspace/helpers/konva';
import { getAreaAutoDirection } from '@modules/workspace/helpers/shape';
import { useDeleteShape } from '@modules/workspace/hooks/useShapeDelete';
import { keyboardState, KEYCODE } from '@recoil/input';
import { AreaShape, DTShape, HighwayShape, ObstacleShape, PositionShape } from '@recoil/shape';
import { enabledVehiclesDetailsSelector } from '@/modules/vehicles';
import { Color, PointerEventArgs, RectElement } from '@thive/canvas';
import { Vector3 } from 'three';

export const useAreaMouseHandler = () => {
  // recoil
  const { t } = useTranslation();
  const { saveFloorPlan } = useFloorPlanState();
  const { runPreValidation } = useRunPreValidation();
  const showNotificationFn = useSetRecoilState(showNotification);
  const keyboard = useRecoilValue(keyboardState);

  // hook
  const { newShape } = useNewShape();
  const { deleteMultipleShapes } = useDeleteShape();
  const { updateConnections } = useConnections();
  const { initSnappingValues, guideLines, previousBoundingBox, getScalingSnapShape } =
    useSnapping();
  const { trackShapeCreation } = useShapeCreationHistory();
  const { getUndoRedoState } = useUndoRedoConnections();
  const { update: updateArtefacts } = useArtefacts();

  // react
  const mouseDownPositionRef = useRef<Vector2d>(null);

  const onMouseDown = useRecoilCallback(
    ({ snapshot, set }) =>
      async (e: PointerEventArgs) => {
        const { x, y } = e.position;
        mouseDownPositionRef.current = { x, y };

        const { shapeType } = await snapshot.getPromise(workspaceAtom);
        const newArea = await newShape(shapeType);
        set(drawingIdSelector, newArea.id); // TODO: check if we might need this after all
        set(initialMousePositionSelector, { x, y });
        initSnappingValues(x, y);

        const canvas = useCanvasStore.getState().instance;
        const areaElement: RectElement = {
          type: 'Rect',
          id: newArea.id,
          name: newArea.name,
          position: new Vector3(x, y, 1),
          size: new Vector3(0, 0, 1),
          fill: Color.fromHex(COLORS[shapeType]),
        };
        canvas.updateElement(areaElement);
      },
    [initSnappingValues, newShape, mouseDownPositionRef],
  );

  const onMouseMove = useRecoilCallback(
    ({ snapshot, set }) =>
      async (e: any) => {
        const drawingId = await snapshot.getPromise(drawingIdSelector);
        if (drawingId == null) return;

        const { x, y } = e.position;
        let { x: sx, y: sy } = await snapshot.getPromise(initialMousePositionSelector);
        let w = x - sx;
        let h = y - sy;

        if (w < 0) {
          w = Math.abs(w);
          sx -= w;
        }

        if (h < 0) {
          h = Math.abs(h);
          sy -= h;
        }

        const boundingBox: BoundingBox = {
          x: Math.round(sx + Math.round(w) / 2),
          y: Math.round(sy + Math.round(h) / 2),
          width: Math.round(w),
          height: Math.round(h),
        };

        const newProperty: ShapeProperties = {
          x: Math.round(sx + Math.round(w) / 2),
          y: Math.round(sy + Math.round(h) / 2),
          width: Math.round(w),
          height: Math.round(h),
          r: 0,
        };

        const shape = await snapshot.getPromise(shapeAtom(drawingId));

        let newShapeAtom = {
          ...shape,
          properties: newProperty,
          parameters: {
            ...shape.parameters,
          },
        } as AreaShape | PositionShape | ObstacleShape | HighwayShape; // shapes handled by this hook

        // Only update the parameters.direction when it's endpoint areas (intake/deliver/etc.)
        if (isAreaShape(shape)) {
          const autoDirection = getAreaAutoDirection(mouseDownPositionRef.current, { x, y });
          newShapeAtom = {
            ...newShapeAtom,
            parameters: {
              ...shape.parameters,
              direction: autoDirection,
            },
          } as AreaShape;
        }

        const canvas = useCanvasStore.getState().instance;
        const areaElement: RectElement = {
          type: 'Rect',
          id: newShapeAtom.id,
          name: newShapeAtom.name,
          position: new Vector3(boundingBox.x, boundingBox.y, 1),
          size: new Vector3(boundingBox.width, boundingBox.height, 1),
        };
        canvas.updateElement(areaElement);

        set(shapeAtom(drawingId), newShapeAtom);
        const shiftKeyPressed = keyboard === KEYCODE.SHIFT;
        if (shiftKeyPressed) {
          console.log('Shift pressed');
          const snapping = await snapshot.getPromise(snappingState);
          if (snapping !== null) set(snappingState, null);
          return;
        }

        if (guideLines) {
          set(
            snappingState,
            getScalingSnapShape(
              boundingBox,
              { ...previousBoundingBox.current },
              guideLines,
              0,
              true,
            ),
          );
          previousBoundingBox.current = boundingBox;
        }
      },
    [guideLines, previousBoundingBox, getScalingSnapShape, mouseDownPositionRef, keyboard],
  );

  const onMouseUp = useRecoilCallback(
    ({ snapshot, set }) =>
      async (e: any) => {
        const drawingId = await snapshot.getPromise(drawingIdSelector);
        if (drawingId == null) return;
        set(drawingIdSelector, null);

        const { x: sx, y: sy } = await snapshot.getPromise(initialMousePositionSelector);
        const { x, y } = e.position;
        let w = x - sx;
        let h = y - sy;
        let newBoundingBox: BoundingBox = {
          x: Math.round(sx + w / 2),
          y: Math.round(sy + h / 2),
          width: Number(w.toFixed(1)),
          height: Number(h.toFixed(1)),
        };

        const vehiclesDetails = await snapshot.getPromise(enabledVehiclesDetailsSelector);
        // TODO: support multiple vehicles
        const vehicleDetails = vehiclesDetails[0];
        const loadCarrier = (await snapshot.getPromise(enabledLoadCarrierTypesSelector))[0];
        const shape = await snapshot.getPromise(shapeAtom(drawingId));
        if (!shape || !shape.parameters) return;

        let minWidth: number;
        let minHeight: number;
        const minSize = getMinimumSizeByShapeType(shape.type);

        if (isAreaShape(shape) || isPositionShape(shape) || isHighwayShape(shape)) {
          const minWidthHighway =
            (vehicleDetails.width + vehicleDetails.safetyMargin + 2 * shape.parameters.margin) *
            SHAPE_TO_CANVAS_SCALE;
          const minHeightHighway =
            (vehicleDetails.length + vehicleDetails.safetyMargin + 2 * shape.parameters.margin) *
            SHAPE_TO_CANVAS_SCALE;

          const minWidthAreasNoLoad =
            (vehicleDetails.width + vehicleDetails.safetyMargin + 2 * shape.parameters.margin) *
            SHAPE_TO_CANVAS_SCALE;
          const minHeightAreasNoLoad =
            (vehicleDetails.length + vehicleDetails.safetyMargin + 2 * shape.parameters.margin) *
            SHAPE_TO_CANVAS_SCALE;

          const minWidthAreasWithLoad =
            (vehicleDetails.width + vehicleDetails.safetyMargin + 2 * shape.parameters.margin) *
            SHAPE_TO_CANVAS_SCALE;
          const minHeightAreasWithLoad =
            Math.max(loadCarrier.length, loadCarrier.width) * SHAPE_TO_CANVAS_SCALE;

          const isHighway = shape.type === ShapeType.HIGHWAY;
          const isAreaNoLoad =
            shape.type === ShapeType.CHARGING || shape.type === ShapeType.PARKING;
          const isAreaWithLoad =
            shape.type === ShapeType.INTAKE ||
            shape.type === ShapeType.STORAGE ||
            shape.type === ShapeType.PROCESS_ONE_EP ||
            shape.type === ShapeType.DELIVERY;

          minWidth = isHighway
            ? minWidthHighway
            : isAreaNoLoad
            ? minWidthAreasNoLoad
            : isAreaWithLoad
            ? minWidthAreasWithLoad
            : minSize;

          minHeight = isHighway
            ? minHeightHighway
            : isAreaNoLoad
            ? minHeightAreasNoLoad
            : isAreaWithLoad
            ? minHeightAreasWithLoad
            : minSize;
        } else {
          minWidth = minSize;
          minHeight = minSize;
        }

        const widthIsToSmall = Math.abs(w) < minWidth;
        const heightIsToSmall = Math.abs(h) < minHeight;

        if (widthIsToSmall || heightIsToSmall) {
          if (w === 0 || h === 0) {
            deleteMultipleShapes([drawingId]);
            set(toolButtonState, null);
            set(drawingIdSelector, null);
            set(snappingState, null);
            return;
          }

          showNotificationFn({
            type: NOTIFICATION_TYPES.INFO,
            message: t('errors:area.too_small_full_message'),
          });
          if (widthIsToSmall) {
            newBoundingBox.width = minWidth;
          }
          if (heightIsToSmall) {
            newBoundingBox.height = minHeight;
          }
        } else {
          set(selectedShapesIdsState, [drawingId]);
        }

        const snappingShape = await snapshot.getPromise(snappingState);
        if (snappingShape) {
          newBoundingBox.x = snappingShape.x;
          newBoundingBox.y = snappingShape.y;
          newBoundingBox.width = snappingShape.width < minWidth ? minWidth : snappingShape.width;
          newBoundingBox.height =
            snappingShape.height < minHeight ? minHeight : snappingShape.height;
        }

        let newShape: DTShape;
        set(shapeAtom(drawingId), (prev) => {
          newShape = {
            ...prev,
            isDrawing: false,
            properties: {
              ...prev.properties,
              x: newBoundingBox.x,
              y: newBoundingBox.y,
              width: newBoundingBox.width,
              height: newBoundingBox.height,
            },
          } as AreaShape | PositionShape | ObstacleShape | HighwayShape;

          return newShape;
        });

        const canvas = useCanvasStore.getState().instance;
        const areaElement: RectElement = {
          type: 'Rect',
          id: newShape.id,
          name: newShape.name,
          position: new Vector3(newBoundingBox.x, newBoundingBox.y, 1),
          size: new Vector3(newBoundingBox.width, newBoundingBox.height, 1),
          interactivity: {
            selectable: true,
            draggable: true,
            rotatable: true,
          },
        };
        canvas.updateElement(areaElement);

        await updateArtefacts([drawingId]);
        await updateConnections([drawingId]);
        const { oldConnectionState, newConnectionState } = await getUndoRedoState([drawingId]);

        const previousShapes = (await snapshot.getPromise(allShapesSelector)).filter(
          (shape) => shape.id !== drawingId,
        );
        trackShapeCreation(
          { shapes: [...previousShapes, newShape], connections: oldConnectionState },
          {
            shapes: previousShapes,
            connections: newConnectionState,
          },
        );

        set(toolButtonState, null);
        set(drawingIdSelector, null);
        set(snappingState, null);
        await saveFloorPlan();
        runPreValidation([PreValidationAspect.DISCONNECTED_FLOW_STOPS]);
      },
    [
      updateConnections,
      saveFloorPlan,
      showNotificationFn,
      t,
      runPreValidation,
      getUndoRedoState,
      trackShapeCreation,
      deleteMultipleShapes,
      updateArtefacts,
    ],
  );

  // do nothing on click, double click
  const onClick = useCallback(async () => {}, []);
  const onDoubleClick = useCallback(async () => {}, []);

  const mouseHandler = useMemo(
    () => ({
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onClick,
      onDoubleClick,
    }),
    [onMouseDown, onMouseMove, onMouseUp, onClick, onDoubleClick],
  );

  return mouseHandler;
};
