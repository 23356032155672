import { DownloadIcon } from '@/assets/icons';
import { PropertiesTitle } from '@/modules/common/components/PropertiesTitle';
import { useFileTypeValidator } from '@/modules/common/hooks/useFileValidator';
import { useSimulationDraftCallbacks } from '@/modules/simulation/hooks';
import { useFmsConfigObjectValidation } from '@/modules/simulation/validation/useFmsConfigObjectValidation';
import { Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function DownloadAndRunSimulationWithFMSConfig() {
  const { t } = useTranslation('interface');
  const { prepareAndRun, prepareAndDownloadFMSConfig } = useSimulationDraftCallbacks();
  const { validateFileType } = useFileTypeValidator();
  const { isValidFmsConfigJson } = useFmsConfigObjectValidation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fmsConfigFile = event.target.files?.[0];

    if (!validateFileType(fmsConfigFile, ['json'])) {
      return;
    }
    if (!fmsConfigFile) {
      console.error('No file selected');
      return;
    }

    const reader = new FileReader();
    reader.onload = async () => {
      const fileContent = reader.result as string;

      if (!isValidFmsConfigJson(fileContent)) {
        return;
      }

      try {
        const fmsConfig = JSON.parse(fileContent);
        await prepareAndRun(fmsConfig);
        console.log('Simulation started with uploaded FMS config:', fmsConfig);
      } catch (error) {
        console.error('Error running simulation with FMS config:', error);
      }
    };

    reader.readAsText(fmsConfigFile);
  };

  const runSimulationWithFMSConfig = () => {
    fileInputRef.current?.click();
  };

  return (
    <Stack spacing={1} mb={2}>
      <PropertiesTitle
        fontSize={14}
        fontWeight={400}
        value={t('simulation.experimental_settings.title')}
      />
      <Typography variant='body2' sx={{ fontSize: '10px' }}>
        {t('simulation.experimental_settings.description')}
      </Typography>
      <Button
        sx={{
          height: 40,
          textTransform: 'none',
          border: 0,
          '&:hover': { color: 'primary.main', backgroundColor: 'transparent' },
        }}
        onClick={prepareAndDownloadFMSConfig}
        endIcon={<DownloadIcon />}
      >
        {t('simulation.experimental_settings.downloadFMSConfig')}
      </Button>
      <Button sx={{ height: 40 }} onClick={runSimulationWithFMSConfig}>
        {t('simulation.experimental_settings.uploadFMSConfig')}
      </Button>
      <input
        type='file'
        accept='.json'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileSelection}
        hidden
      />
    </Stack>
  );
}
