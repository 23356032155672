import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useResetRecoilState } from 'recoil';

import { useDeleteControlPoint } from '@/modules/workspace/hooks/points';
import { useWorkspaceStore } from '@/modules/workspace/store';
import { DeleteIcon } from '@assets/icons';
import { AngledHighwayShape } from '@modules/angledHighways/types';
import { useContextMenu } from '@modules/workspace/hooks';
import { Button, MenuItem } from '@mui/material';
import objectUnderMouseIdSelector from '@recoil/input/objectUnderMouseIdSelector';
import { NOTIFICATION_TYPES, showNotification } from '@recoil/notification';
import shapeAtom from '@recoil/shape/atom';
import { selectedShapesIdsState } from '@recoil/shapes/selected';
import { relatedControlPointIdState } from '@recoil/workspace/contextMenu';

const DeleteControlPointOptionComponent = () => {
  const contextRelatedControlPointId = useRecoilValue(relatedControlPointIdState);

  if (!contextRelatedControlPointId) return null;

  return <DeleteControlPointOptionContent controlPointId={contextRelatedControlPointId} />;
};

export const DeleteControlPointOption = memo(DeleteControlPointOptionComponent);

type DeleteControlPointOptionContentProps = {
  controlPointId: string;
};

const DeleteControlPointOptionContent = ({
  controlPointId,
}: DeleteControlPointOptionContentProps) => {
  // hooks
  const { t } = useTranslation();
  const { deleteControlPoint } = useDeleteControlPoint();
  const { hide } = useContextMenu();

  // recoil
  const highwayInEditModeId = useWorkspaceStore((state) => state.shapeInEditModeIdState);
  const resetRelatedControlPointId = useResetRecoilState(relatedControlPointIdState);

  // const disabled = !highwayInEditMode || highwayInEditMode.properties.controlPoints.length < 2 ;
  const disabled = false;

  const clickHandler = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async () => {
        const highwayInEditMode = (await snapshot.getPromise(
          shapeAtom(highwayInEditModeId),
        )) as AngledHighwayShape;

        if (highwayInEditMode.properties.controlPoints.length === 2) {
          hide();

          set(showNotification, {
            type: NOTIFICATION_TYPES.WARNING,
            message: t('errors:angled_highway.control_point_deletion_cascades_to_highway_deletion'),
            action: (
              <Button
                color='inherit'
                size='small'
                onClick={() => {
                  set(objectUnderMouseIdSelector, (current) =>
                    current === highwayInEditModeId ? null : current,
                  );
                  set(selectedShapesIdsState, (current) =>
                    current.includes(highwayInEditModeId)
                      ? current.filter((id) => id !== highwayInEditModeId)
                      : current,
                  );
                  useWorkspaceStore.getState().reset();
                  deleteControlPoint(controlPointId, highwayInEditModeId);
                }}
              >
                {t('common:confirm')}
              </Button>
            ),
          });

          return;
        }

        hide();
        deleteControlPoint(controlPointId, highwayInEditModeId);
      },
    [hide, deleteControlPoint, controlPointId, highwayInEditModeId],
  );

  useEffect(() => () => resetRelatedControlPointId(), [resetRelatedControlPointId]);

  return (
    <MenuItem
      disabled={disabled}
      onClick={clickHandler}
      component='button'
      sx={{
        display: 'flex',
        gap: '14px',
        justifyContent: 'space-between',
      }}
    >
      {t('interface:context_menu.workspace.control_point.delete')}
      <DeleteIcon />
    </MenuItem>
  );
};
