import NotificationsIcon from '@/assets/icons/NotificationsIcon';
import { IconButton } from '@mui/material';
import { useRecoilState } from 'recoil';
import notificationPanelOpen from '../../../store/recoil/notification/notificationPanelOpen';

export function NotificationHistory() {
  const [open, setOpen] = useRecoilState(notificationPanelOpen);

  return (
    <>
      <IconButton
        sx={{ p: 1 }}
        onClick={() => setOpen(!open)}
      >
        <NotificationsIcon sx={{ width: '24px', height: '24px' }} />
      </IconButton>
    </>
  );
}
