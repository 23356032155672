import FolderZipIcon from '@mui/icons-material/FolderZip';
import ImageIcon from '@mui/icons-material/Image';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';

import { download } from '@modules/common/helpers/browser';
import { groupNameSelector } from '@modules/floorplan';
import { OutputFiles } from '@modules/floorplanService';
import { fpsWarningsSelector } from '@modules/floorplanService/store';
import { useZoomButton } from '@modules/workspace/components/ZoomButton/useZoomButton';
import { allShapesSelector } from '@recoil/shapes';
import { selectedShapesIdsState } from '@recoil/shapes/selected';

type Props = {
  output: OutputFiles;
};

type FormattedShape = {
  name: string;
  id: string;
  sequence: number;
};

type FormattedWarning = {
  code: string;
  shapes: FormattedShape[];
  sequence: number;
};

export function ExportOutput({ output }: Props) {
  const { t } = useTranslation(['interface']);
  const { zoomFitShape } = useZoomButton();
  const setSelectedShapesIds = useSetRecoilState(selectedShapesIdsState);
  const [formattedWarnings, setFormattedWarnings] = useState<FormattedWarning[]>([]);
  const name = useRecoilValue(groupNameSelector);
  const warnings = useRecoilValue(fpsWarningsSelector);

  const handleWarningClick = useCallback(
    (id) => {
      zoomFitShape(id);
      setSelectedShapesIds([id]);
    },
    [setSelectedShapesIds, zoomFitShape],
  );

  const handleDownloadZip = useCallback(() => {
    download(output.zipFile, `${name}.zip`);
  }, [name, output.zipFile]);

  const openWindow = useCallback((file: File) => {
    window.open(URL.createObjectURL(file), '_blank').focus();
  }, []);

  const formatWarningMessages = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const out: FormattedWarning[] = [];
        const allShapes = await snapshot.getPromise(allShapesSelector);
        const allShapeIds = allShapes.map((shape) => shape.id);

        warnings.forEach((warning) => {
          const ids = warning.args.filter((arg) =>
            allShapeIds.includes(arg.argument.split('_')[0]),
          );
          const names = warning.args.filter(
            (arg) => !allShapeIds.includes(arg.argument.split('_')[0]),
          );

          const shapes: FormattedShape[] = [];
          for (let i = 0; i < ids.length; i++) {
            if (names.length > i) {
              shapes.push({
                name: names[i].argument,
                id: ids[i].argument.split('_')[0],
                sequence: i,
              });
            }
          }
          out.push({ code: warning.code, shapes, sequence: warning.sequence });
        });

        setFormattedWarnings(out);
      },
    [],
  );

  useEffect(() => {
    formatWarningMessages();
  }, [formatWarningMessages, warnings]);

  return (
    <List sx={{ marginTop: '0.4em' }}>
      {output.zipFile && (
        <ListItem disablePadding>
          <ListItemButton onClick={handleDownloadZip}>
            <ListItemIcon>
              <FolderZipIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('interface:export.download_prompt', 'Download the output zip file')}
            />
          </ListItemButton>
        </ListItem>
      )}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:export.files_label', 'Files')}
      </ListSubheader>
      {output.files &&
        output.files.map((file: File) => (
          <ListItem disablePadding key={file.name}>
            <ListItemButton onClick={() => openWindow(file)}>
              <ListItemIcon>
                <ImageIcon />
              </ListItemIcon>
              <ListItemText primary={file.name} />
            </ListItemButton>
          </ListItem>
        ))}
      <Divider />
      <ListSubheader sx={{ '&:hover': { cursor: 'default' } }}>
        {t('interface:export.warnings_label', 'Warnings')}
      </ListSubheader>
      {formattedWarnings.map((warning) => (
        <div key={warning.sequence}>
          <Divider variant='middle' />
          <ListItem sx={{ pr: 2, pl: 2 }}>
            <ListItemText>{warning.code}</ListItemText>
          </ListItem>
          {warning.shapes.map((shape) => (
            <ListItemButton
              onClick={() => handleWarningClick(shape.id)}
              key={`${warning.sequence}${shape.sequence}`}
              sx={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <ListItemText>{shape.name}</ListItemText>
            </ListItemButton>
          ))}
        </div>
      ))}
    </List>
  );
}
