import {
  KM_MDB_ASSET_FILE_CONTAINER_DEFAULT,
  UVT_ASSET_FILE_CONTAINER_DEFAULT,
} from '@/modules/vehicles/constants';
import { useUvtAssetFileSyncing } from '@/modules/vehicles/hooks';
import JSZip from 'jszip';
import { v4 as uuid } from 'uuid';

export const useZipFileProcessor = () => {
  const { uploadAssetFileToFileStorage } = useUvtAssetFileSyncing();

  const parseZipFile = async (zip) => {
    const kmMdbFiles = [];
    const uvtFiles = [];
    let floorplan = null;

    await Promise.all(
      Object.keys(zip.files).map(async (relativePath) => {
        const zipEntry = zip.files[relativePath];

        if (!zipEntry.dir) {
          if (relativePath.startsWith('kmMdb/')) {
            const fileContent = await zipEntry.async('blob');
            kmMdbFiles.push({ vehicleVariantId: relativePath.slice(6, -4), content: fileContent });
          } else if (relativePath.startsWith('uvt/')) {
            const fileContent = await zipEntry.async('string');
            uvtFiles.push({ vehicleVariantId: relativePath.slice(4, -5), content: fileContent });
          } else if (relativePath.endsWith('.json')) {
            const fileContent = await zipEntry.async('string');
            floorplan = JSON.parse(fileContent);
          } else {
            console.error('Unrecognized file in ZIP:', relativePath);
          }
        }
      }),
    );

    return { kmMdbFiles, uvtFiles, floorplan };
  };

  const uploadFile = async (fileContent, type, vehicleName) => {
    const newFileReferenceId = `${vehicleName}_${
      type === 'json' ? UVT_ASSET_FILE_CONTAINER_DEFAULT : KM_MDB_ASSET_FILE_CONTAINER_DEFAULT
    }_${uuid()}`;
    const result = await uploadAssetFileToFileStorage(fileContent, newFileReferenceId, type);

    if (!result.success) {
      console.error(`Failed to upload '${type}' asset to file API:`, newFileReferenceId);
      return null;
    }

    return newFileReferenceId;
  };

  const processKmMdbFiles = async (kmMdbFiles, floorplan) => {
    await Promise.all(
      kmMdbFiles.map(async (file) => {
        const newFileReferenceId = await uploadFile(
          new Blob([file.content], { type: 'application/vnd.ms-access' }),
          'mdb',
          file.vehicleVariantId,
        );

        if (!newFileReferenceId) return;

        const matchingAsset = floorplan.additionalData.vehicleAssets.find(
          (vehicleAsset) => vehicleAsset.vehicleVariantId === file.vehicleVariantId,
        );

        if (matchingAsset) {
          matchingAsset.kmMdbReference = newFileReferenceId;
          matchingAsset.kmMdbReferenceOriginal = false;
        }
      }),
    );
  };

  const processUvtFiles = async (uvtFiles, floorplan) => {
    await Promise.all(
      uvtFiles.map(async (file) => {
        const newFileReferenceId = await uploadFile(
          new Blob([file.content], { type: 'application/json' }),
          'json',
          file.vehicleVariantId,
        );

        if (!newFileReferenceId) return;

        const matchingAsset = floorplan.additionalData.vehicleAssets.find(
          (vehicleAsset) => vehicleAsset.vehicleVariantId === file.vehicleVariantId,
        );

        if (matchingAsset) {
          matchingAsset.uvtReference = newFileReferenceId;
          matchingAsset.uvtReferenceOriginal = false;
        }
      }),
    );
  };

  const handleZipUpload = async (file) => {
    try {
      const arrayBuffer = await file.arrayBuffer();
      const zip = await JSZip.loadAsync(arrayBuffer);

      const { kmMdbFiles, uvtFiles, floorplan } = await parseZipFile(zip);

      if (kmMdbFiles.length > 0) await processKmMdbFiles(kmMdbFiles, floorplan);
      if (uvtFiles.length > 0) await processUvtFiles(uvtFiles, floorplan);

      if (!floorplan) {
        console.error('Floorplan JSON not found in the ZIP file.');
        return null;
      }

      const updatedFloorplanBlob = new Blob([JSON.stringify(floorplan)], {
        type: 'application/json',
      });
      return new File([updatedFloorplanBlob], 'updatedFloorplan.json', {
        type: 'application/json',
      });
    } catch (error) {
      console.error('Error processing ZIP file:', error);
    }
  };

  const createAssetZip = async (uvtFiles, uvtFileNames, kmMdbFiles, kmMdbFileNames, floorplan) => {
    const zip = new JSZip();

    if (kmMdbFiles.length) {
      const kmMdbFolder = zip.folder('kmMdb');
      kmMdbFiles.forEach((file, index) => {
        kmMdbFolder.file(
          `${kmMdbFileNames[index]}.mdb`,
          new Blob([file.data], { type: 'application/vnd.ms-access' }),
        );
      });
    }

    if (uvtFiles.length) {
      const uvtFolder = zip.folder('uvt');
      uvtFiles.forEach((file, index) => {
        uvtFolder.file(
          `${uvtFileNames[index]}.json`,
          new Blob([JSON.stringify(file.data, null, 2)], { type: 'application/json' }),
        );
      });
    }

    if (floorplan) {
      zip.file(floorplan.name, floorplan);
    }

    return await zip.generateAsync({ type: 'blob' });
  };

  return {
    handleZipUpload,
    createAssetZip,
  };
};
