import { selector } from 'recoil';
import { v4 as uuid } from 'uuid';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { notificationAtom, NotificationProps, NotificationQueue } from './atom';

const showNotification = selector<NotificationProps>({
  key: 'notification/open',
  get: ({ get }) => get(notificationAtom).queue[0],
  set: ({ get, set }, notificationProps: NotificationProps): void => {
    const currentState = get(notificationAtom);
    const oldQueue = currentState.queue;

    if (notificationProps.keepInHistory === undefined)
      // eslint-disable-next-line no-param-reassign
      notificationProps.keepInHistory = true;

    const id = notificationProps.id ?? uuid();

    if (
      !notificationProps.removeDuplicates ||
      ![...currentState.queue, ...currentState.history].find((item) => item.id === id)
    ) {
      const queue: NotificationQueue = [
        ...oldQueue,
        { ...notificationProps, id, time: new Date() },
      ];

      set(notificationAtom, {
        ...currentState,
        queue,
      });
    }
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default showNotification;
