import { Accordion, Button } from '@mui/material';
import { useCallback } from 'react';
import { SUPPORTED_FILE_TYPES } from '@/modules/common/helpers/excel';
import { useSetRecoilState } from 'recoil';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';
import { openFile } from '@/modules/common/helpers/browser';

import { useGateStore } from '../../store';

export const ImportExport = () => {
  const notify = useSetRecoilState(showNotification);

  const onDownloadTemplateClick = useCallback(() => {
    useGateStore.getState().downloadExcelTemplate();
  }, []);

  const onUploadFileClick = useCallback(async () => {
    const files = await openFile(SUPPORTED_FILE_TYPES);

    if (files?.length > 0) {
      const result = await useGateStore.getState().uploadExcel(files[0]);

      if (result.status === 'error') {
        notify({
          type: NOTIFICATION_TYPES.ERROR,
          message: result.error,
        });
      }
    }
  }, [notify]);

  return (
    <Accordion title='All positions' sx={{ px: 2 }}>
      <>
        <Button onClick={onDownloadTemplateClick}>Download Template</Button>
        <Button onClick={onUploadFileClick}>Upload file</Button>
      </>
    </Accordion>
  );
};
