import { requestWithTimeout } from '@/modules/api/helpers';
import { useFileApi, useFloorPlanServiceApi } from '@/modules/api/hooks';
import { getCurrentArtefactAdjustments } from '@/modules/commissioning';
import {
  isAngledHighwayShape,
  isHighwayShape,
  isObstacleShape,
  isProcessAreaTwoEp,
  isWallShape
} from '@/modules/common/types/guards';
import { allCrossingSelector } from '@/modules/connections/crossings';
import { FloorplanServiceOption } from '@/modules/floorplanService/enum';
import { createExportSettings } from '@/modules/floorplanService/helpers/floorplanServiceHelpers';
import { allGroupsSelector } from '@/modules/shapeGroups';
import {
  allVehicleAssetsSelector,
  enabledVehicleIdsState,
  enabledVehiclesDetailsSelector,
  enabledVehiclesSelector,
} from '@/modules/vehicles';
import { allShapesSelector } from '@/store/recoil/shapes';
import { allConnectionsSelector } from '@modules/connections/connections';
import { allDistantConnectionsSelector } from '@modules/connections/distant';
import { groupNameSelector, versionSelector } from '@modules/floorplan';
import {
  CheckGenerationProgressOptions,
  FileGenerationOutput,
  GenerateFloorPlanExportSettings,
  GenerateFloorPlanOptions,
  GeneratedFloorPlanArtefacts,
  IdGenerator,
  ServiceStatus,
} from '@modules/floorplanService/types';
import { enabledLoadCarrierTypesSelector } from '@recoil/loadCarrierTypes';
import JSZip from 'jszip';
import { useCallback } from 'react';
import { useRecoilCallback } from 'recoil';
import { mapStateToPayload } from '../helpers/mapping';
import { customIdGeneratorsState } from '@/modules/floorplanService';

const URL =
  '/FloorPlanService/JsonServices/com.thive.floorplan.service.connectors.FloorPlan.FloorPlanServiceConnector/';

const DOWNLOAD_URL = '/FloorPlanService/download?generatedFloorPlanId=';

export const useFloorPlanService = () => {
  const floorPlanServiceApi = useFloorPlanServiceApi();
  const fileApi = useFileApi();

  /**
   * Creates payload for FloorPlanService. If no shapeIds are provided, the whole floorplan will be used
   */
  const createFloorPlanPayload = useRecoilCallback(
    ({ snapshot }) =>
      async (exportSettings: GenerateFloorPlanExportSettings, shapeIds: string[] = []) => {
        let [
          name,
          floorPlanVersion,
          shapes,
          groups,
          connections,
          crossings,
          distantConnections,
          vehicles,
          vehicleIds,
          vehicleDetails,
          vehicleAssets,
          loadTypes,
          customIdGenerators,
        ] = await Promise.all([
          snapshot.getPromise(groupNameSelector),
          snapshot.getPromise(versionSelector),
          snapshot.getPromise(allShapesSelector),
          snapshot.getPromise(allGroupsSelector),
          snapshot.getPromise(allConnectionsSelector),
          snapshot.getPromise(allCrossingSelector),
          snapshot.getPromise(allDistantConnectionsSelector),
          snapshot.getPromise(enabledVehiclesSelector),
          snapshot.getPromise(enabledVehicleIdsState),
          snapshot.getPromise(enabledVehiclesDetailsSelector),
          snapshot.getPromise(allVehicleAssetsSelector),
          snapshot.getPromise(enabledLoadCarrierTypesSelector),
          snapshot.getPromise(customIdGeneratorsState),
        ]);
        const { locationAdjustments, loadPositionAdjustments } = getCurrentArtefactAdjustments();
        const customIdGeneratorMap = new Map<string, IdGenerator>();

        customIdGenerators.forEach((item) => {
          customIdGeneratorMap.set(item.name, item);
        });

        if (shapeIds.length > 0) {
          shapes = shapes.filter((shape) => shapeIds.includes(shape.id));
          groups = groups.filter((group) =>
            group.children.every((child) => shapeIds.includes(child)),
          );
          connections = connections.filter(
            (connection) => shapeIds.includes(connection.from) && shapeIds.includes(connection.to),
          );
          crossings = crossings.filter(
            (crossing) => shapeIds.includes(crossing.from) && shapeIds.includes(crossing.to),
          );
          distantConnections = distantConnections.filter(
            (distantConnection) =>
              shapeIds.includes(distantConnection.from) && shapeIds.includes(distantConnection.to),
          );

          const [...vehicleIdsRelatedToShapes] = shapes.reduce((acc, item) => {
            if (
              isObstacleShape(item) ||
              isWallShape(item)
            )
              return acc;
            if (
              isHighwayShape(item) ||
              isAngledHighwayShape(item)
            ) {
              vehicleIds.forEach((item) => acc.add(item));
              return acc;
            }
            if (isProcessAreaTwoEp(item)) {
              item.parameters.intakeParameters.supportedVehicleIds.forEach((item) => acc.add(item));
              item.parameters.deliveryParameters.supportedVehicleIds.forEach((item) => acc.add(item));
              return acc
            }
            item.parameters.supportedVehicleIds.forEach((item) => acc.add(item));
            return acc;
          }, new Set<string>());

          vehicleAssets = vehicleAssets.filter((item) =>
            vehicleIdsRelatedToShapes.includes(item.vehicleVariantId),
          );
        }

        return [
          {
            SecurityToken: '',
            InputArgs: [
              {
                input: mapStateToPayload(
                  name,
                  `${floorPlanVersion}.0`,
                  shapes,
                  groups,
                  loadTypes,
                  vehicles,
                  vehicleDetails,
                  vehicleAssets,
                  connections,
                  crossings,
                  distantConnections,
                  exportSettings,
                  locationAdjustments,
                  loadPositionAdjustments,
                  fileApi.baseUrl,
                  customIdGeneratorMap,
                ),
              },
            ],
          },
        ];
      },
    [],
  );

  const getFloorPlanDefinition = useCallback(
    async (exportSettings: GenerateFloorPlanExportSettings) => {
      const payload = await createFloorPlanPayload(exportSettings);

      return payload[0].InputArgs[0].input.definition;
    },
    [createFloorPlanPayload],
  );

  const partialFloorPlanGeneration = useCallback(
    async (
      options: GenerateFloorPlanOptions,
      shapeIds: string[],
    ): Promise<GeneratedFloorPlanArtefacts> => {
      const payload = await createFloorPlanPayload(options.exportSettings, shapeIds);
      const response = await floorPlanServiceApi.post(`${URL}/generatePartialFloorPlan`, payload, {
        signal: options?.abortController?.signal,
      });

      return {
        locations: response.data.value.locations,
        loadPositions: response.data.value.loadPositions,
      };
    },
    [createFloorPlanPayload, floorPlanServiceApi],
  );

  const checkGenerationStatus = useCallback(
    async (
      generatedFloorPlanId: string,
      { abortController }: CheckGenerationProgressOptions = {},
    ) => {
      const viewResponse = await floorPlanServiceApi.post(
        `${URL}/viewGeneratedFloorPlan`,
        createViewFloorPlanPayload(generatedFloorPlanId),
        {
          signal: abortController?.signal,
        },
      );

      return viewResponse.data;
    },
    [floorPlanServiceApi],
  );

  const startGeneratingFloorPlan = useCallback(
    async (options: GenerateFloorPlanOptions) => {
      const payload = await createFloorPlanPayload(options.exportSettings);
      const response = await floorPlanServiceApi.post(`${URL}/generateFloorPlan`, payload, {
        signal: options?.abortController?.signal,
      });

      return response.data;
    },
    [createFloorPlanPayload, floorPlanServiceApi],
  );

  const generateFloorPlan = useCallback(
    async (options: GenerateFloorPlanOptions) => {
      const response = await startGeneratingFloorPlan(options);

      return await requestWithTimeout(
        async () => {
          const status = await checkGenerationStatus(response.value.generatedFloorPlanId, options);
          return status.value;
        },
        (data: any) => {
          data.warningMessages = response.value.warningMessages;
          if (data.status === ServiceStatus.Completed) {
            options.progressCallback?.(100);

            return false;
          }

          if (data.status === ServiceStatus.Pending || data.status === ServiceStatus.InProgress) {
            options.progressCallback?.(data.generationProgress ?? 0);

            return true;
          }

          if (data.status === ServiceStatus.Failed) {
            options.progressCallback?.(data.generationProgress ?? 0);
            throw new Error('Response generation failed');
          }

          return false;
        },
        5000,
        Infinity,
      );
    },
    [startGeneratingFloorPlan, checkGenerationStatus],
  );

  const generateOutputFiles = useCallback(
    async (options: GenerateFloorPlanOptions): Promise<FileGenerationOutput> => {
      const data = await generateFloorPlan(options);
      const downloadUrl = `${DOWNLOAD_URL}${data.generatedFloorPlanId}`;
      const response = await floorPlanServiceApi.get(downloadUrl, {
        responseType: 'arraybuffer',
      });

      const zipFile = await JSZip.loadAsync(response.data);
      const filePromises = Object.keys(zipFile.files).map(async (fileName) => {
        const blob = await zipFile.file(fileName).async('blob');
        return new File([blob], fileName, {
          type: fileName.endsWith('html') ? 'text/html' : 'text/plain',
        });
      });

      return {
        outputUri: downloadUrl,
        zipFile: response.data,
        files: await Promise.all(filePromises),
        warnings: data.warningMessages,
      };
    },
    [floorPlanServiceApi, generateFloorPlan],
  );

  const generateFloorPlanArtefacts = async (): Promise<GeneratedFloorPlanArtefacts> => {
    const response = await startGeneratingFloorPlan({
      exportSettings: createExportSettings(FloorplanServiceOption.Artefacts),
    });

    return {
      locations: response.value.locations,
      loadPositions: response.value.loadPositions,
    };
  };

  return {
    generateOutputFiles,
    generateFloorPlanArtefacts,
    getFloorPlanDefinition,
    partialFloorPlanGeneration,
  };
};

const createViewFloorPlanPayload = (generatedFloorPlanId: string) => [
  {
    SecurityToken: '',
    InputArgs: [
      {
        key: {
          generatedFloorPlanId,
        },
      },
    ],
  },
];
