import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { notificationAtom } from './atom';

const notificationPanelOpen = selector<boolean>({
  key: 'notification/panelOpen',
  get: ({ get }) => get(notificationAtom).open,
  set: ({ set, get }, value) => {
    set(notificationAtom, {
      ...get(notificationAtom),
      open: value
    });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default notificationPanelOpen;
