import { useTranslation } from 'react-i18next';
import { StatusChip } from '@common/components/StatusChip';
import { Stack, SxProps } from '@mui/material';

import { Simulation, SimulationStatus, SimulationType } from '../helpers/types';
import { SimulationStatusChip } from './SimulationStatusChip';

type Props = {
  simulation: Simulation;
  sx?: SxProps;
};

export const Status = ({ simulation, sx }: Props) => {
  const { t } = useTranslation('interface');

  return (
    <Stack sx={{ flexDirection: 'row', gap: '4px', ...sx }}>
      {simulation.type === SimulationType.GROUP && (
        <StatusChip status={t(`simulation.run_card.group`)} color='info.main' />
      )}
      <SimulationStatusChip
        status={simulation.status}
        message={
          simulation.status === SimulationStatus.FAILED
            ? `${t('simulation.errors.run')} - ${simulation.error}`
            : null
        }
      />
    </Stack>
  );
};
