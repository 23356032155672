import { useEffect } from 'react';
import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { NotificationsContext } from '../constants';
import { notificationsContextState } from '../store/state';

export const useNotificationsContext = (notificationContext: NotificationsContext) => {
  const setNotificationsContextState = useSetRecoilState(notificationsContextState);
  const resetToDefault = useResetRecoilState(notificationsContextState);

  useEffect(() => {
    setNotificationsContextState(notificationContext);

    return () => {
      resetToDefault();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
