import { memo, SVGProps } from 'react';

function SvgComponent({ className = '', ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='1em'
      height='1em'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
      aria-label='Error Icon'
    >
      <path
        d='M22.553 22.581a.569.569 0 0 1-.553.894H2a.57.57 0 0 1-.553-.894L11.553 2.37c.246-.492.648-.492.894 0l10.106 20.211ZM12 16.979v-7'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.991 18.979a.246.246 0 0 0-.24.255.255.255 0 0 0 .253.245h.005a.246.246 0 0 0 .241-.255.255.255 0 0 0-.25-.245h-.005'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

const ErrorIcon = memo(SvgComponent);
export default ErrorIcon;
