import { clearNotification } from '@/store/recoil/notification';
import { Alert, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

const textSx = { color: 'neutral.darker', weight: 400, lineHeight: '150%px' };

export function NotificationCard({ notification }) {
  const clearNotificationFn = useSetRecoilState(clearNotification);
  const { t } = useTranslation('interface');

  return (
    <Alert
      onClose={() => {
        clearNotificationFn(notification.id);
      }}
      severity={notification.type}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '8px 8px 8px 8px',
        '& .MuiAlert-icon': {
          padding: 0,
        },
        '& .MuiAlert-message': {
          padding: 0,
        },
        '& .MuiAlert-action': {
          marginLeft: 'auto',
          marginRight: '0px',
          padding: 0,
        },
      }}
    >
      <Stack>
        <Typography fontSize={10} sx={{ ...textSx, color: 'neutral.dark' }}>
          {notification.time.toLocaleTimeString()}
        </Typography>
        {notification.showSupportMessage && (
          <Typography fontSize={12} sx={textSx}>
            {t('errors:notifications.support.label', 'Sorry we encounter a problem.')}
          </Typography>
        )}
        <Typography fontSize={12} sx={textSx}>
          {notification.message}
        </Typography>
        {notification.showSupportMessage && (
          <Typography fontSize={12} sx={textSx}>
            {t(
              'errors:notifications.support.message',
              'Appreciate if you can take you time to download your floorplan and send it to support@t-hive.io with the error code as the subject. Thanks in advanced',
            )}
          </Typography>
        )}
      </Stack>
    </Alert>
  );
}
