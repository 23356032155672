import { useCallback } from 'react';
import { NOTIFICATION_TYPES, showNotification } from '@/store/recoil/notification';

import { useKollmorgenStore } from '../../store';
import { useSaveData } from '../../hooks';
import { LocalizationMapType } from '../../helpers/types';
import { FileMultiList } from './FileMultiList';
import { useSetRecoilState } from 'recoil';

export const KollmorgenUpload = () => {
  const { setFile, fileName, deleteFile } = useKollmorgenStore();
  const { deleteLocalizationMap, saveLocalizationMap } = useSaveData();
  const showNotificationFn = useSetRecoilState(showNotification);

  const onFileSelected = useCallback(
    async (file: File) => {
      try {
        await setFile(file);
        await saveLocalizationMap(LocalizationMapType.Kollmorgen, file);
      } catch (e) {
        showNotificationFn({
          type: NOTIFICATION_TYPES.ERROR,
          message: e.message,
        });
      }
    },
    [setFile, saveLocalizationMap, showNotificationFn],
  );

  const onDeleteClick = useCallback(async () => {
    deleteFile();
    await deleteLocalizationMap(LocalizationMapType.Kollmorgen);
  }, [deleteFile, deleteLocalizationMap]);

  return (
    <FileMultiList
      accept='.txt'
      fileName={fileName}
      titleKey='interface:commissioning.kollmorgen.label'
      onDeleteClick={onDeleteClick}
      onFileSelected={onFileSelected}
    />
  );
};
