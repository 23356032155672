import { selector } from 'recoil';
import { RECOIL_SELECTOR_CACHE_POLICY } from '../common';
import { notificationAtom } from './atom';

const clearNotification = selector({
  key: 'notification/clear',
  get: () => '',
  set: ({ get, set }, id: string) => {
    const currentState = get(notificationAtom);
    const { queue } = currentState;
    const { open } = currentState;
    const { history } = currentState;
    
    const filteredQueue = queue.filter((n) => n.id !== id);
    const filteredHistory = history.filter((n) => n.id !== id);
    
    set(notificationAtom, { queue: filteredQueue, history: filteredHistory, open });
  },
  cachePolicy_UNSTABLE: RECOIL_SELECTOR_CACHE_POLICY.MOST_RECENT,
});

export default clearNotification;
