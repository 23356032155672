import { Toolbar3 } from '@/components/Toolbar3';
import { Module } from '@/modules/common/types/navigation';
import { FlowUI } from '@/modules/flows/layout';
import { ReferenceUi } from '@/modules/referenceImage';
import { WifiSimulationPanel } from '@/modules/wifisimulation/components/WifiSimulationPanel';
import { ContextMenu } from '@/modules/workspace/components';
import { navSelector } from '@/store/recoil/nav';
import { toolState } from '@/store/recoil/workspace';
import { modeSelector } from '@modules/common/store/workspace';
import { WorkspaceMode } from '@modules/common/types/general';
import { ToolState } from '@modules/common/types/tools';
import { useRecoilValue } from 'recoil';
import { SettingsPanel } from '../SettingsPanel';

export const WorkspaceUi = () => {
  const wsToolState = useRecoilValue(toolState);
  const nav = useRecoilValue(navSelector);
  const mode = useRecoilValue(modeSelector);
  const disabled = mode !== WorkspaceMode.EDITABLE;

  return (
    <>
      <Toolbar3 />
      {nav === Module.SETUP && !disabled && <ReferenceUi />}
      {nav === Module.LAYOUT && wsToolState === ToolState.FLOW && !disabled && <FlowUI />}
      <ContextMenu />

      {/* Floating Panel */}
      <SettingsPanel />
      <WifiSimulationPanel />
    </>
  );
};
